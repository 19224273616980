import { useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export type Activitystream = {
  category_name: string;
  slug: string;
  name: string;
  group_slug?: string;
  externally_visible?: boolean;
  meta?: boolean;
  can?: {
    create?: boolean;
    email_recommend_activity_internally?: boolean;
    email_recommend_activity_externally?: boolean;
    create_appointment_activity?: boolean;
  };
};

/*
 * This hook is currently not used in activitystreams/Userbar to deduplicate the /activitystreams.json request
 * that is issued from app/assets/javascripts/activitystreams/index.js.coffee:23 too
 */
export function useActivitystreams() {
  return useQuery<Activitystream[], Error>(
    "activitystreams",
    () => fetchApi("/activitystreams.json"),
    { staleTime: Infinity },
  );
}
