import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn as faLightBullhorn,
  faEarthEurope as faLightEarthEurope,
  faInbox as faLightInbox,
  faCalendarDay as faLightCalendarDay,
  faCircleCheck as faLightCircleCheck,
  faFolderOpen as faLightFolderOpen,
  faUser as faLightUser,
  faEllipsis as faLightEllipsis,
  faBrush as faLightBrush,
  faCircleInfo as faLightCircleInfo,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBullhorn as faDuotoneBullhorn,
  faEarthEurope as faDuotoneEarthEurope,
  faInbox as faDuotoneInbox,
  faCalendarDay as faDuotoneCalendarDay,
  faCircleCheck as faDuotoneCircleCheck,
  faFolderOpen as faDuotoneFolderOpen,
  faBrush as faDuotoneBrush,
  faCircleInfo as faDuotoneCircleInfo,
} from "@fortawesome/pro-duotone-svg-icons";
import ModuleSwitcher from "./ModuleSwitcher";
import { isEmpty, map } from "lodash";
import { useSelector } from "react-redux";
import { useFeaturesUnreads } from "api/unreads";
import { useLocation } from "helpers/tixxt-router";
import { State } from "../../@types";

type ModuleConfigLookup = {
  [moduleName: string]:
    | {
        title: string;
        icon: (opts: { duo: boolean; active: boolean }) => React.ReactNode;
        slug: string;
        url: string;
      }
    | undefined;
};
export const MODULE_LOOKUP: ModuleConfigLookup = {
  get Channels() {
    let url: string | undefined =
      Preload.current_network.config.channels?.start_page;
    if (!url || isEmpty(url)) url = "/channels";

    return {
      title: I18n.t("js.channels.module_name"),
      icon: ({ duo, active }) => (
        <FontAwesomeIcon
          icon={duo ? faDuotoneBullhorn : faLightBullhorn}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "channels",
      url,
    };
  },
  get Activitystreams() {
    return {
      title: I18n.t("js.activitystreams.module_name"),
      icon: ({ duo, active }) => (
        <FontAwesomeIcon
          icon={duo ? faDuotoneEarthEurope : faLightEarthEurope}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "activities",
      url: "/activitystreams/all",
    };
  },
  get Messages() {
    return {
      title: I18n.t("js.messages.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon icon={duo ? faDuotoneInbox : faLightInbox} />
      ),
      slug: "messages",
      url: "/messages",
    };
  },
  get Calendars() {
    return {
      title: I18n.t("js.calendars.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon
          icon={duo ? faDuotoneCalendarDay : faLightCalendarDay}
        />
      ),
      slug: "appointments",
      url: "/appointments",
    };
  },
  get Tasks() {
    return {
      title: I18n.t("js.tasks.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon
          icon={duo ? faDuotoneCircleCheck : faLightCircleCheck}
        />
      ),
      slug: "tasks",
      url: "/tasks/my",
    };
  },
  get Files() {
    return {
      title: I18n.t("js.files.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon icon={duo ? faDuotoneFolderOpen : faLightFolderOpen} />
      ),
      slug: "files",
      url: "/files",
    };
  },
  get Directory() {
    return {
      title: "Kontakte",
      icon: ({ active }) => (
        <FontAwesomeIcon
          icon={faLightUser}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "directory",
      url: "/directory",
    };
  },
  get More() {
    return {
      title: "Mehr",
      icon: ({ active }) => (
        <FontAwesomeIcon
          icon={faLightEllipsis}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "more",
      url: "/more",
    };
  },
};

type Props = {
  activeModule?:
    | "channels"
    | "activities"
    | "messages"
    | "appointments"
    | "tasks"
    | "files"
    | "directory"
    | "more"
    | "info";
};

export default function TixxtModuleSwitcher({ activeModule }: Props) {
  const location = useLocation();
  const theme = useSelector((state: State) => state.theme);
  const duo = theme?.userbarIconType == "duotone";
  const currentNetwork = useSelector(
    (state: { network: { availableModules: string[] } }) => state.network,
  );
  const { data: unreads } = useFeaturesUnreads();

  return (
    <ModuleSwitcher className="userbar__module-switcher">
      {map(currentNetwork.availableModules, (moduleName, index) => {
        const module = MODULE_LOOKUP[moduleName];
        const notifications = unreads?.[moduleName];

        return (
          module && (
            <ModuleSwitcher.Module
              key={index}
              {...module}
              icon={module?.icon?.({
                duo,
                active: module.slug === activeModule,
              })}
              active={module.slug === activeModule}
              notifications={notifications}
            />
          )
        );
      })}

      {location.pathname.indexOf("/administration/designs") === 0 ||
      theme?.override ? (
        <ModuleSwitcher.Module
          title={"Design"}
          url={"/administration/designs"}
          active={location.pathname.indexOf("/administration/designs") === 0}
          icon={
            duo ? (
              <FontAwesomeIcon icon={faDuotoneBrush} />
            ) : (
              <FontAwesomeIcon icon={faLightBrush} />
            )
          }
        />
      ) : null}

      <ModuleSwitcher.Module
        className="module-switcher__footer"
        key="info"
        title="Info"
        url="/imprint"
        active={activeModule === "info"}
        icon={
          <FontAwesomeIcon
            icon={duo ? faDuotoneCircleInfo : faLightCircleInfo}
            className={activeModule === "info" ? "fa-swap-opacity" : ""}
          />
        }
      />
    </ModuleSwitcher>
  );
}
