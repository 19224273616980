import { UseFormRegister } from "react-hook-form";
import React from "react";
import classNames from "classnames";
import useRegisterPropOrFromContext from "components/shared/form/fields/useRegisterPropOrFromContext";

export default function Checkbox({
  name,
  register,
  label,
  disabled = false,
  className,
}: {
  name: string;
  label: string;
  register?: UseFormRegister<any>;
  disabled?: boolean;
  className?: string;
}) {
  register = useRegisterPropOrFromContext(register);

  return (
    <label
      className={classNames("checkbox gap-2 items-center", className, {
        "text-muted": disabled,
      })}
    >
      <input type="checkbox" {...register(name)} disabled={disabled} />
      {label}
    </label>
  );
}
