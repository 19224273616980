import React from "react";
import { first, get, isEmpty } from "lodash";
import { CrawlerLink } from "components/channels/widgets/widgetsFields/linkWidget/types";
import LinkView from "components/channels/widgets/widgetsFields/linkWidget/LinkView";
import EmbedLinkView from "components/channels/widgets/widgetsFields/linkWidget/EmbedLinkView";

function getImageAsset(type: string) {
  switch (type) {
    case "activity":
      return "widgets/activity_placeholder.jpg";
    case "file":
    case "folder":
      return "widgets/file_placeholder.jpg";
    case "appointment":
      return "appointments/event_image_default.jpg";
    default:
      return "widgets/link_placeholder.jpg";
  }
}

type LinkWidgetArgs = {
  link?: CrawlerLink;
  url: string;
  selected_image: string | null;
  override_image: { id: string; state: string }[];
  override_description: string | null;
};

export default function LinkWidget({
  url,
  selected_image,
  override_image,
  override_description,
  link,
}: LinkWidgetArgs) {
  const type =
    link?.crawl_type ||
    (!isEmpty(link?.embed) && "embed") ||
    link?.id?.split("_")[0] ||
    "external_link";

  return (
    link &&
    (!isEmpty(link.embed) ? (
      <EmbedLinkView
        embed={link.embed}
        secureEmbedServiceUrl={link.secure_embed_service_url}
        image_url={
          override_image
            ? `/api/storage/images/${get(
                first(override_image),
                "id",
              )}/get/1920x1200`
            : selected_image
        }
      />
    ) : (
      <LinkView
        image_url={`${
          override_image
            ? `/api/storage/images/${get(
                first(override_image),
                "id",
              )}/get/1920x1200`
            : selected_image
              ? selected_image
              : Assets[getImageAsset(type)]
        }`}
        url={url}
        title={link.title}
        type={type}
        description={
          !isEmpty(override_description)
            ? override_description
            : link.description
        }
      />
    ))
  );
}
