import React, { useContext } from "react";
import { Article } from "components/articles/types";
import ItemCarousel from "components/shared/ItemCarousel";
import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import { map } from "lodash";
import { useNavigate } from "helpers/tixxt-router";
import useContainerWidth from "components/shared/hooks/useContainerWidth";

export default function SpotlightWidget({
  articles,
  hide_title,
  label,
  title_background_override,
}: {
  articles: Article[];
  label?: string | null;
  hide_title: boolean;
  title_background_override: string;
}) {
  const navigate = useNavigate();
  const { containerRef } = useContext(WidgetContext);
  const containerWidth = useContainerWidth(containerRef);

  return (
    articles && (
      <ItemCarousel
        containerWidth={containerWidth}
        visibleItemsCount={1}
        totalItems={articles?.length}
        navigationButtonsStyle={
          title_background_override
            ? {
                color: title_background_override,
              }
            : undefined
        }
        containerHeight={hide_title ? "h-[24rem]" : "h-[21rem]"}
      >
        {map(articles, (article) => (
          <div
            key={article.id}
            className="w-full h-full bg-cover bg-center hover:cursor-pointer"
            onClick={() =>
              navigate(`/blogs/${article.blog_id}/articles/${article.id}`)
            }
            style={{
              backgroundImage: `url("${
                article.image_path
                  ? article.image_path
                  : Assets["blogs/article_placeholder.webp"]
              }")`,
            }}
          >
            <div className="flex flex-col gap-2 z-50 w-full h-full justify-end px-14 pb-14 bg-gradient-to-t from-[#000000]/95 to-transparent">
              {label && (
                <div>
                  <span
                    className={`widget__label widget__label--${label} font-medium`}
                  >
                    {I18n.t(`js.channels.widgets.labels.${label}`)}
                  </span>
                </div>
              )}
              <span className="text-2xl font-bold text-white line-clamp-3 overflow-hidden text-ellipsis">
                {article.title}
              </span>
            </div>
          </div>
        ))}
      </ItemCarousel>
    )
  );
}
