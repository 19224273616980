import React, { useRef, useEffect, useState } from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { isEmpty } from "lodash";
import Datepicker from "helpers/Datepicker";
import "../../../../../../node_modules/vanillajs-datepicker/dist/css/datepicker-bs5";

import styles from "./editFields.module.css";
import FieldError from "components/appCreator/items/form/FieldError";

type DatePickerAdapterArgs = {
  input: {
    name: string;
    value: string;
    onChange: (value: string | null) => void;
  };
  meta: {
    error?: string;
  };
};

function DatePickerAdapter({ input, meta }: DatePickerAdapterArgs) {
  const elemRef = useRef<HTMLInputElement>(null);
  const [displayValue, setDisplayValue] = useState(
    !isEmpty(input.value)
      ? moment(input.value).format(
          I18n.t("js.plugins.Locale.Formats.valid_date"),
        )
      : "",
  );

  useEffect(() => {
    const datepicker = new Datepicker(elemRef.current);
    elemRef.current?.addEventListener(
      "changeDate",
      function (e: CustomEventInit) {
        const formattedDate = e.detail.date
          ? moment(
              e.detail.date,
              I18n.t("js.plugins.Locale.Formats.valid_date"),
            ).format("YYYY-MM-DD")
          : null;

        setDisplayValue(
          formattedDate
            ? moment(formattedDate).format(
                I18n.t("js.plugins.Locale.Formats.valid_date"),
              )
            : "",
        );

        input.onChange(formattedDate);
      },
    );

    return () => {
      datepicker.destroy();
    };
  });

  function onChange(e) {
    const rawValue = e.target.value;
    setDisplayValue(rawValue);

    const date = moment(
      rawValue,
      I18n.t("js.plugins.Locale.Formats.valid_date"),
      true,
    );

    if (date.isValid()) {
      input.onChange(date.format("YYYY-MM-DD"));
    } else {
      input.onChange(null);
    }
  }

  return (
    <>
      <div className={`${styles.Date} input-group date`}>
        <input
          type="text"
          className={"form-control"}
          value={displayValue}
          onChange={onChange}
          ref={elemRef}
        />
        <button
          className="input-group-text"
          onClick={(e) => {
            e.preventDefault();
            elemRef.current?.focus();
          }}
        >
          <FontAwesomeIcon icon={faCalendar} />
        </button>
      </div>
      {meta?.error && <FieldError error={meta.error} />}
    </>
  );
}

type DateFieldArgs = {
  name: string;
  disabled: boolean;
  required: boolean;
  id: string;
};

function DateField({ required, id, disabled, name }: DateFieldArgs) {
  return (
    <Field
      component={DatePickerAdapter}
      className={`property-${name} form-input`}
      name={name}
      required={required}
      id={id}
      disabled={disabled}
    />
  );
}

export default DateField;
