import React, { lazy, Suspense } from "react";
import { RouteObject, useParams } from "helpers/tixxt-router";
import NetworkSettings from "./networkSettings";
import NavigationManager from "./NavigationManager";
import GroupCategorySorter from "./GroupCategorySorter";
import MembershipEdit from "./MembershipEdit";
import MembershipNew from "./MembershipNew";
import LegalTextEdit from "../administration/legalTexts/LegalTextEdit";
import LegalTextIndex from "../administration/legalTexts/LegalTextIndex";
import LegalTextNew from "../administration/legalTexts/LegalTextNew";
import LegalTextVersions from "../administration/legalTexts/LegalTextVersions";
import ImprintEdit from "../administration/imprints/ImprintEdit";
import AppNavigationManager from "./appNavigationManager";
import { convertRoutes } from "components/tixxt/BackboneFeature";
import GroupAdministrationMainPage from "components/administration/GroupAdministrationMainPage";

const DesignsOverviewPage = lazy(
  () => import("./designManager/DesignsOverviewPage"),
);
const DesignsOverviewLightPage = lazy(
  () => import("./designManagerLight/DesignsOverviewLightPage"),
);
const DesignManagerPage = lazy(
  () => import("./designManager/DesignManagerPage"),
);
const DesignManagerLightPage = lazy(
  () => import("./designManagerLight/DesignManagerLightPage"),
);

const StatisticsPage = lazy(() => import("./statistics/StatisticsPage"));
const TagsManagerPage = lazy(() => import("./tags/TagsManagerPage"));

function Params({ children }) {
  const params = useParams();
  return children(params);
}

const backboneRoutes: RouteObject[] = convertRoutes({
  feature: "Administration",
  featureRoutes: {
    "group-categories/:group_category_id/administration/groups":
      "editSectionGroups",
    "administration/groups": "editGroups",
    "(groups/:group_slug/)administration/members": "editMembers",
    "administration/members/:id/group-memberships":
      "editMemberGroupMemberships",
    "(groups/:group_slug/)administration/membership_roles":
      "manageMembershipRoles",
    "(groups/:group_slug/)administration/consumers": "consumers",
    "administration/consumers/new": "newConsumer",
    "administration/consumers/:id/edit": "editConsumer",
    "administration/consumers/:id/group-memberships":
      "editConsumerGroupMemberships",
    "administration/group-categories/new": "newGroupCategory",
    "administration/group-categories/:id/edit": "editGroupCategory",
    "administration/abbreviations": "abbreviations",
    "administration/abbreviations/new": "newAbbreviation",
    "administration/abbreviations/:id/edit": "editAbbreviation",
    "(groups/:group_slug/)administration/widgetbars": "widgetbarIndex",
    "(groups/:group_slug/)administration/widgetbars/:slug/edit":
      "widgetbarEdit",
    "groups/:group_slug/administration/organisation": "organisationMain",
    "groups/:group_slug/administration/organisation/group-memberships":
      "organisationGroups",
    "groups/:group_slug/administration/organisation/group-memberships/:id":
      "organisationGroupMemberships",
    "groups/:group_slug/administration/organisation/members":
      "organisationMembers",
    "groups/:group_slug/administration/organisation/members/new":
      "newOrganisationMember",
    "groups/:group_slug/administration/organisation/edit":
      "organisationProfile",
    "groups/:group_slug/administration/organisation/feedback":
      "organisationFeedback",
    "administration/industrials": "industrials",
    "administration/industrials/new": "newIndustrial",
    "administration/industrials/:id/edit": "editIndustrial",
  },
});

const reactRoutes: RouteObject[] = [
  { path: "/administration/settings/edit", element: <NetworkSettings /> },
  {
    path: "/administration/navigation/edit",
    element: <NavigationManager groupSlug={null} />,
  },
  {
    path: "/administration/app_navigation/edit",
    element: <AppNavigationManager />,
  },
  {
    path: "/administration/group-categories-order/edit",
    element: <GroupCategorySorter />,
  },
  {
    path: "/administration/members/:membershipId/edit",
    element: <MembershipEdit />,
  },
  { path: "/administration/members/new", element: <MembershipNew /> },
  { path: "/administration/legal_texts", element: <LegalTextIndex /> },
  {
    path: "/administration/legal_texts/:legalTextId/edit",
    element: <LegalTextEdit />,
  },
  { path: "/administration/legal_texts/new", element: <LegalTextNew /> },
  {
    path: "/administration/legal_texts/:legalTextId",
    element: <LegalTextVersions />,
  },
  { path: "/administration/imprint/edit", element: <ImprintEdit /> },
  {
    path: "/administration/statistics",
    element: (
      <Suspense fallback={"Loading..."}>
        <StatisticsPage />
      </Suspense>
    ),
    forceTurbo: true, // for user tracking #3324
  },
  {
    path: "/administration/designs",
    element: (
      <Suspense fallback={"Loading..."}>
        <DesignsOverviewPage themeType={"network"} />
      </Suspense>
    ),
  },
  {
    path: "/administration/designs/:id/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <DesignManagerPage themeType={"network"} />
      </Suspense>
    ),
  },
  {
    path: "/group-categories/:groupCategoryId/administration/designs",
    element: (
      <Suspense fallback={"Loading..."}>
        <DesignsOverviewLightPage themeType={"groupCategory"} />
      </Suspense>
    ),
  },
  {
    path: "/group-categories/:groupCategoryId/administration/designs/:designId/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <DesignManagerLightPage themeType={"groupCategory"} />
      </Suspense>
    ),
  },
  {
    path: "/administration/tags",
    element: (
      <Suspense fallback={"Loading..."}>
        <TagsManagerPage />
      </Suspense>
    ),
  },
  {
    path: "/groups/:groupSlug/administration",
    element: <GroupAdministrationMainPage />,
  },
  {
    path: "/groups/:groupSlug/administration/designs",
    element: (
      <Suspense fallback={"Loading..."}>
        <DesignsOverviewLightPage themeType={"group"} />
      </Suspense>
    ),
  },
  {
    path: "/groups/:groupSlug/administration/designs/:designId/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <DesignManagerLightPage themeType={"group"} />
      </Suspense>
    ),
  },
  {
    path: "/groups/:groupSlug/administration/navigation/edit",
    element: (
      <Params>
        {({ groupSlug }) => <NavigationManager groupSlug={groupSlug} />}
      </Params>
    ),
  },
  {
    path: "/groups/:groupSlug/administration/statistics",
    element: (
      <Suspense fallback={"Loading..."}>
        <StatisticsPage />
      </Suspense>
    ),
  },
];
export default backboneRoutes.concat(reactRoutes);
