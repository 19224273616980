import React from "react";
import moment from "moment/moment";
import { useFormContext, useWatch } from "react-hook-form";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Label from "components/shared/form/Label";
import { compact, isEmpty, map } from "lodash";
import DateTimePicker from "components/shared/form/fields/DateTimePicker";
import classNames from "classnames";
import PartialToggle from "components/appointments/form/PartialToggle";
import Select from "components/shared/form/fields/Select";
import Checkbox from "components/shared/form/fields/Checkbox";

type DateSelectArgs = {
  name: string;
  label?: string;
  required?: boolean;
};
export default function DateRangeSelectField({
  label,
  required = false,
}: DateSelectArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={required} />}
      <div className="controls border-box">
        <DateRangeSelect />
      </div>
    </div>
  );
}

function DateRangeSelect() {
  const { setValue } = useFormContext();
  const { all_day, start, time_zone, end } = useWatch({
    control: undefined,
    name: "date",
  });

  const selectOptions = compact(
    map(moment.tz.names(), (zone) => {
      const invalid = zone.startsWith("Etc/") || /^[A-Z0-9+-]+$/.test(zone);

      if (!invalid) {
        return {
          value: zone,
          label: `${zone.replace(/\//g, ", ").replace(/_/g, " ")} (${moment.tz
            .zone(zone)
            ?.abbr(moment.tz(start, time_zone.value).valueOf())})`,
        };
      } else {
        return null;
      }
    }),
  );

  return (
    <div className="flex flex-col p-3 gap-y-3">
      <DateTimePicker
        name="date.start"
        label={I18n.t("js.calendars.date_range.start_date.label")}
        showTimePicker={!all_day}
        showTimezone
        timeZone={time_zone.value}
      />
      <Checkbox
        name="date.all_day"
        label={I18n.t("js.calendars.date_range.all_day")}
      />
      <PartialToggle
        onToggle={(showPartial) => {
          setValue(
            "date.end",
            showPartial ? null : end || moment(start).add(1, "hour").format(),
          );
        }}
        label={(showPartial) =>
          showPartial
            ? I18n.t("js.calendars.date_range.remove_end")
            : I18n.t("js.calendars.date_range.set_end.link")
        }
        hideIcon={faXmark}
        iconClassName={(showPartial) =>
          classNames({ "text-danger": showPartial })
        }
        shouldShowPartial={!isEmpty(end)}
      >
        <DateTimePicker
          name="date.end"
          label={I18n.t("js.calendars.date_range.end")}
          showTimePicker={!all_day}
        />
      </PartialToggle>

      {!all_day && (
        <>
          <PartialToggle
            label={I18n.t("js.calendars.date_range.timezone.select_label")}
          >
            <Select
              label={I18n.t("js.calendars.date_range.timezone.label")}
              name="date.time_zone"
              options={selectOptions}
            />
          </PartialToggle>
        </>
      )}
    </div>
  );
}
