import React, { useRef, useEffect } from "react";
import "../../../../vendor/assets/javascripts/spectrum/spectrum.css";

interface SpectrumProps {
  value: string;
  showPalette?: boolean;
  colorPalette?: any;
  name?: string;
  onChange: (props: { target: { value: string } }) => void;
  disabled?: boolean;
}

function Spectrum(props: SpectrumProps) {
  const ref = useRef(null);
  let el;

  useEffect(() => {
    if (!el && ref.current) {
      el = $(ref.current);
      el.spectrum({
        showInput: true,
        preferredFormat: "hex",
        showInitial: true,
        showPalette: !!props.showPalette,
        palette: props.colorPalette,
        cancelText: I18n.t("plugins.spectrum.cancel"),
        chooseText: I18n.t("plugins.spectrum.choose"),
        change: (color) =>
          props.onChange({
            target: {
              value: color.toHexString(),
            },
          }),
        disabled: props.disabled,
        color: props.value,
      });
    } else {
      el.spectrum("set", props.value);
    }
    return () => el.spectrum("destroy");
  }, [ref.current, props.value]);

  return (
    <div>
      <input
        type="color"
        ref={ref}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}

export default Spectrum;
