import { enGB, de, es, fr } from "date-fns/locale";
import { format, formatDistanceToNow, setDefaultOptions } from "date-fns";
import documentReady from "helpers/documentReady";
import { includes, split } from "lodash";

const LOCALE_LOOKUP = {
  de_DU: de,
  de: de,
  en: enGB,
  es: es,
  fr: fr,
};

documentReady(() => {
  let currentLocale = I18n.currentLocale();
  if (includes(currentLocale, "_") && currentLocale !== "de_DU")
    currentLocale = split(currentLocale, "_")[0];

  setDefaultOptions({ locale: LOCALE_LOOKUP[currentLocale] });
});

export default function formatDate(
  date: Date | number,
  dateFormat: string,
  options = {},
) {
  return format(date, dateFormat, {
    ...options,
  });
}

export function formatDateFromNow(date: Date | number, options = {}) {
  return formatDistanceToNow(date, {
    ...options,
  });
}
