import React from "react";
import Label from "components/shared/form/Label";
import { Control, useController } from "react-hook-form";
import DirectoryPicker, {
  DirectoryPickerProps,
} from "components/shared/DirectoryPicker";
import Hint from "components/shared/fields/Hint";
import { MultiValue, SingleValue } from "react-select";
import { ValueType } from "components/shared/DirectoryPicker/types";
import classNames from "classnames";

type InputArgs = {
  name: string;
  control?: Control<any>;
  label?: string;
  hint?: string;
  required?: string | boolean;
  placeholder?: string;
  multiline?: boolean;
  maxLength?: number;
  defaultValue?: string;
  apiToValues?: (
    apiValues: any,
  ) => MultiValue<ValueType> | SingleValue<ValueType>;
  valuesToApi?: (
    formValues: MultiValue<ValueType> | SingleValue<ValueType>,
  ) => any;
  children?: React.ReactNode;
};

export default function DirectoryPickerInput({
  label,
  control,
  name,
  hint,
  required = false,
  defaultValue,
  apiToValues,
  valuesToApi,
  children,
  ...directoryPickerProps
}: InputArgs & DirectoryPickerProps) {
  const {
    field,
    fieldState: { invalid },
  } = useController({
    name,
    rules: { required },
    defaultValue,
    control,
  });

  return (
    <div className="control-group">
      {label && <Label label={label} required={!!required} />}
      <div
        className={classNames("controls", {
          "border-box p-3": !!children,
          "field-error": invalid,
        })}
      >
        <DirectoryPicker
          {...directoryPickerProps}
          {...field}
          value={apiToValues ? apiToValues(field.value) : field.value}
          onChange={(values) =>
            field.onChange(valuesToApi ? valuesToApi(values) : values)
          }
        />
        {hint && <Hint hint={hint} />}
        {children}
      </div>
    </div>
  );
}
