import React from "react";
import classnames from "classnames";

interface FormActionsProps {
  pristine?: boolean;
  processing?: boolean;
  submitting: boolean;

  reset?(...args: unknown[]): unknown;

  submitText?: string;
  submitIcon?: string;

  onCancel?(...args: unknown[]): unknown;

  className?: string;
}

function FormActions({
  processing,
  pristine,
  submitting,
  reset,
  submitText,
  submitIcon,
  onCancel,
  className,
}: FormActionsProps) {
  return (
    <div className={classnames("tixxt__form--actions", className)}>
      <a
        href="#"
        className="btn btn-light"
        onClick={
          onCancel
            ? (e) => {
                e.preventDefault();
                onCancel(reset);
              }
            : (e) => {
                e.preventDefault();
                reset?.();
                history.back();
              }
        }
      >
        {I18n.t("js.cancel")}
      </a>
      <button
        className="btn btn-primary"
        type="submit"
        disabled={pristine || submitting || processing}
      >
        {submitIcon !== "no-icon" && (
          <>
            <i
              key="icon"
              className={submitIcon ? submitIcon : "fa-regular fa-floppy-disk"}
            />{" "}
          </>
        )}

        {submitText ? submitText : I18n.t("js.save")}
      </button>
    </div>
  );
}

export default FormActions;
