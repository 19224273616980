import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faUsers,
  faEarthEurope,
} from "@fortawesome/pro-regular-svg-icons";

const FolderIcons = {
  folder: faFolder,
  root_folder: faUsers,
  activitystream_folder: faEarthEurope,
};

type FolderIconArgs = {
  type?: string;
  muted?: boolean;
  className?: string;
};
function FolderIcon({ type, muted, className }: FolderIconArgs) {
  return (
    <FontAwesomeIcon
      icon={FolderIcons[type || "folder"]}
      className={classNames(`icons-folder`, className, {
        "text-muted": !!muted,
      })}
    />
  );
}
FolderIcon.propTypes = { type: PropTypes.string, muted: PropTypes.bool };

export default FolderIcon;
