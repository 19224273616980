import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { archiveGroup, deleteGroup } from "./api";
import DangerZone from "../shared/form/DangerZone";

function EditGroupActions({ archived, can, slug, name }) {
  const { mutate: onDeleteGroup } = useMutation(deleteGroup, {
    onSuccess: ({ redirect }) => {
      toastr.success(I18n.t("groups.destroy.success", { group: name }));
      // eslint-disable-next-line no-undef
      Turbo.visit(redirect, {
        action: "replace",
        target: document.documentElement,
        historyChanged: true,
      });
    },
  });

  const { mutate: onArchiveGroup } = useMutation(archiveGroup, {
    onSuccess: ({ archived, redirect }) => {
      toastr.success(
        I18n.t(
          archived
            ? "js.groups.group.successfully_archived"
            : "js.groups.group.successfully_unarchived",
          { group_name: name },
        ),
      );
      // eslint-disable-next-line no-undef
      Turbo.visit(redirect, {
        action: "replace",
        target: document.documentElement,
        historyChanged: true,
      });
    },
  });

  return (
    <DangerZone
      title={I18n.t(
        archived ? "js.groups.edit.is_archived" : "js.groups.edit.do_archive",
      )}
      body={I18n.t(
        archived
          ? "js.groups.edit.help_do_unarchive"
          : "js.groups.edit.help_do_archive",
      )}
      actions={
        <>
          <a
            className={"btn btn-light"}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.bridge.confirm(
                I18n.t(
                  archived
                    ? "js.groups.edit.confirm_unarchive"
                    : "js.groups.edit.confirm_archive",
                ),
                () => onArchiveGroup({ slug, archived }),
              );
            }}
          >
            {I18n.t(
              archived ? "js.groups.edit.unarchive" : "js.groups.edit.archive",
            )}
          </a>
          {can.destroy ? (
            <a
              className="btn btn-danger"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.bridge.confirm(
                  I18n.t("js.groups.edit.confirm_delete"),
                  () => onDeleteGroup({ slug }),
                );
              }}
            >
              {I18n.t("js.groups.edit.delete")}
            </a>
          ) : null}
        </>
      }
    />
  );
}

EditGroupActions.propTypes = {
  archived: PropTypes.bool,
  can: PropTypes.shape({
    archive: PropTypes.bool,
    destroy: PropTypes.bool,
  }),
  slug: PropTypes.string,
};

export default EditGroupActions;
