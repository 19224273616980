import moment, { Moment } from "moment";
import { filter, flatten, isEmpty, reduce } from "lodash";
import { Appointment, AppointmentDate } from "../../@types/appointments";

const DATE_FORMAT = "YYYY-MM-DD";

export function weeksInRange(rangeStart, rangeEnd) {
  const currentDate = moment(rangeStart);
  const weeks: string[][] = [];
  const days: string[] = [];

  while (currentDate <= rangeEnd) {
    days.push(currentDate.format(DATE_FORMAT));
    currentDate.add(1, "days");
  }

  let week: string[] = [];
  let i = moment(days[0]).weekday();

  for (const day of days) {
    week.push(day);

    if ((i + 1) % 7 === 0) {
      weeks.push(week);
      week = [];
    }

    i++;
  }

  if (week.length > 0) {
    weeks.push(week);
  }
  return weeks;
}

export function datesInRange(date: string): string[] {
  const rangeStart = moment(date).startOf("month").startOf("isoWeek");
  const rangeEnd = moment(date).endOf("month").endOf("isoWeek");

  return flatten(weeksInRange(rangeStart, rangeEnd));
}

export function appointmentsInDateRange(
  appointments: Appointment[],
  date: string,
) {
  return filter(appointments, (appointment) => {
    let isInRange = false;

    if (appointment.date.end) {
      isInRange = moment(date).isBetween(
        moment(appointment.date.start).startOf("day"),
        moment(appointment.date.end).endOf("day"),
        null,
        "[]",
      );
    } else {
      isInRange = moment(date).isSame(moment(appointment.date.start), "day");
    }

    return isInRange;
  });
}

export function collectAppointmentsByDate(
  appointments: Appointment[] | undefined,
  date: string,
) {
  if (!appointments) return {};

  const range = datesInRange(date);

  return reduce(
    range,
    (result, date) => {
      result[date] = appointmentsInDateRange(appointments, date);
      return result;
    },
    {},
  );
}

export function weeksInMonth(date: string) {
  const rangeStart = moment(date).startOf("month");
  const rangeEnd = moment(date).endOf("month");

  return weeksInRange(rangeStart, rangeEnd);
}

const msToDayFactor = 1000 * 60 * 60 * 24; // ms to second to minute to day

export function dateStamp(date: Moment) {
  const offsetMinutes = date.utcOffset() || date.zone() * -1;

  if (date) {
    return Math.floor(
      date.valueOf() / msToDayFactor + offsetMinutes / (60 * 24),
    );
  } else {
    return 0;
  }
}

export function dateStampsRange(appointmentDate: AppointmentDate) {
  const start = dateStamp(moment(appointmentDate.start));
  let end = start;

  if (!isEmpty(appointmentDate.end)) {
    end = dateStamp(moment(appointmentDate.end));
  }

  return [start, end];
}

export function spansDate(date: Moment, appointmentDate: AppointmentDate) {
  const stamp = dateStamp(date);
  const range = dateStampsRange(appointmentDate);

  return range[0] < stamp && range[1] > stamp;
}

export function spansMultipleDays(appointmentDate: AppointmentDate) {
  const range = dateStampsRange(appointmentDate);
  return range[0] != range[1];
}
