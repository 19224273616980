import React, { useEffect, useRef } from "react";
import Datepicker from "helpers/Datepicker";
import moment from "moment";
import { isEmpty, noop } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import TimePicker from "components/shared/form/fields/TimePicker";
import Label from "components/shared/form/Label";
import { Control, useController } from "react-hook-form";

type DateTimePickerArgs = {
  name: string;
  showTimePicker?: boolean;
  label?: string;
  showTimezone?: boolean;
  timeZone?: string;
  interval?: moment.Duration;
  required?: boolean | string;
  control?: Control<any>;
  defaultValue?: string;
};

export default function DateTimePicker({
  name,
  showTimePicker = false,
  label,
  showTimezone = false,
  timeZone,
  interval,
  required = false,
  control,
  defaultValue,
}: DateTimePickerArgs) {
  const { field } = useController({
    name,
    rules: { required },
    defaultValue: required ? defaultValue || moment().format() : "",
    control,
  });
  const pickerRef = useRef<HTMLInputElement>(null);

  function isDateSelectable(pickerDate: string) {
    const momentDate = moment(pickerDate);
    const startDate = moment(field.value).clone();
    if (momentDate.isBefore(startDate, "day")) {
      return false;
    }
    const checkDate = startDate.clone().startOf("day");
    while (checkDate.isSameOrBefore(momentDate)) {
      if (checkDate.isSame(momentDate, "day")) {
        return true;
      }
      checkDate.add(interval);
    }
    return false;
  }

  useEffect(() => {
    const options = interval
      ? {
          beforeShowDay: (pickerDate: string) => ({
            enabled: isDateSelectable(pickerDate),
          }),
        }
      : {};

    const picker = new Datepicker(pickerRef.current, options);

    pickerRef.current?.addEventListener("changeDate", (e: CustomEventInit) => {
      field.onChange(
        e.detail.date && moment(e.detail.date).isValid()
          ? moment(e.detail.date)
              .hours(moment(field.value).hours())
              .minutes(moment(field.value).minutes())
              .format()
          : "",
      );
    });

    return () => {
      picker.destroy();
    };
  }, [interval]);

  return (
    <div className="control-group items-center">
      {label && <Label label={label} required={!!required} />}
      <div className="controls flex gap-2 items-start">
        <div className="input-group w-36">
          <input
            type="text"
            ref={pickerRef}
            placeholder={I18n.t("js.calendars.date_range.date_placeholder")}
            value={
              isEmpty(field.value)
                ? ""
                : moment(field.value).format(
                    I18n.t("js.plugins.Locale.Formats.valid_date"),
                  )
            }
            onChange={noop}
          />
          <button
            className="btn btn-light btn-sm"
            onClick={(e) => {
              e.preventDefault();
              pickerRef?.current?.focus();
            }}
          >
            <FontAwesomeIcon icon={faCalendar} />
          </button>
        </div>
        {showTimePicker && (
          <div className="grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-[auto_1fr] gap-2 items-center">
            <TimePicker onChange={field.onChange} value={field.value} />
            <span className="text-sm w-40">
              {showTimezone &&
                timeZone &&
                `UTC ${moment.tz(field.value, timeZone).format("Z")} (${moment
                  .tz(field.value, timeZone)
                  .format("z")})`}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
