import React from "react";

interface ArticleTypes {
  title: string;
  teaser: string;
  body: string;
  article_id: string;
  blog_id: string;
}

function Article({ title, teaser, article_id, blog_id }: ArticleTypes) {
  return (
    <div className="article-view prose">
      <h1 className={"font-bold text-2xl"}>{title}</h1>
      <h3>{teaser}</h3>
      <a href={`/blogs/${blog_id}/article/${article_id}`}>
        {I18n.t("js.activitystreams.activity.article.show_article")}
      </a>
    </div>
  );
}

export default Article;
