import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "../store";
import queryClient from "../helpers/queryClient";
import {
  HeaderPortal,
  UserbarPortal,
  ChatbarPortal,
  ContentPortal,
} from "components/layout/portals";
import TixxtUserbar from "components/layout/TixxtUserbar";
import TixxtChatbar from "components/layout/TixxtChatbar";
import TixxtContent from "components/layout/TixxtContent";
import TixxtHeader from "components/layout/TixxtHeader";
import { StaticTixxtGroupNavigation } from "components/layout/TixxtGroupNavigation";
import { StaticTixxtPageTitle } from "components/layout/TixxtPageTitle";
import ApplyOverrideTheme from "components/administration/designManager/ApplyOverrideTheme";
import ApplyOverrideGroupTheme from "components/administration/designManagerLight/ApplyOverrideGroupTheme";
import ApplyOverrideGroupCategoryTheme from "./administration/designManagerLight/ApplyOverrideGroupCategoryTheme";
import GenericErrorBoundary from "components/shared/GenericErrorBoundary";
import { BrowserRouter } from "helpers/tixxt-router";
import AppSearchHeader from "components/layout/AppSearchHeader";
import { TurboFrameMissingEvent } from "@hotwired/turbo";
import ApplyOverrideChannelTheme from "components/channels/design/ApplyOverrideChannelTheme";

// For debugging only
document.addEventListener("turbo:visit", function (event) {
  debug("⚡️ turbo:visit", new URL(event["detail"].url).pathname);
});

// Fix Content Missing outside of development
// In development it produces an error and should be fixed
if (!window.Settings?.development) {
  document.addEventListener("turbo:frame-missing", function (
    event: TurboFrameMissingEvent,
  ) {
    event.preventDefault();
    event.detail.visit(event.detail.response, {});
  } as EventListener);
}

/*
 SPA entry point
 */
export function launchApp() {
  function setupReact() {
    const container = document.getElementById("app-root");
    const root = createRoot(container!);
    root.render(<App />);
    document.removeEventListener("turbo:load", setupReact);
  }

  // Setup only once
  document.addEventListener("turbo:load", setupReact);
}

/*
 * Main frontend component
 */
export default function App() {
  const currentMember = Preload.current_member;
  const canChat = currentMember?.can.chat;

  useEffect(() => {
    if (window.isApp) {
      window.bridge?.setMemberIsLoggedIn(currentMember != null);
      if (currentMember != null) {
        window.bridge?.setMemberId(currentMember.id);
      }
      window.bridge?.setMemberCanChat(!!canChat);
    }
  }, [currentMember]);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GenericErrorBoundary headless>
            <ApplyOverrideTheme />
            <ApplyOverrideGroupCategoryTheme />
            <ApplyOverrideGroupTheme />
            <ApplyOverrideChannelTheme />
          </GenericErrorBoundary>
          <HeaderPortal>
            {window.isApp ? <AppSearchHeader /> : <TixxtHeader />}
          </HeaderPortal>
          <UserbarPortal>
            <TixxtUserbar />
          </UserbarPortal>
          {canChat &&
            !window.isApp &&
            window.location.pathname !== "/legal" && (
              <ChatbarPortal>
                <TixxtChatbar />
              </ChatbarPortal>
            )}
          <StaticTixxtGroupNavigation />
          <StaticTixxtPageTitle />
          {window.Turbo?.session.drive ? (
            <ContentPortal>
              <TixxtContent />
            </ContentPortal>
          ) : null}
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
}
