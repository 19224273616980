import "@hotwired/turbo";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import moment from "moment-timezone";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/de";
import jQuery from "jquery";
import lodash from "lodash";
import hljs from "highlight.js";
import ClipboardJS from "clipboard/src/clipboard";

// Stimulus
import "../controllers";

// Basic layout styles and components
import "../layout/index";

import store from "store";
import wrapProvider from "../helpers/components/wrapProvider";

import ActivityComposer from "components/activitystreams/composer";
import ReplyComposer from "components/activitystreams/replyComposer";
import adaptedFileComponents from "components/files/adaptedFileComponents";
import adaptedSharedComponents from "components/shared/adaptedSharedComponents";
import adaptedAppCreatorComponents from "components/appCreatorTemporary/adaptedAppCreatorComponents";
import adaptedAdministrationComponents from "components/administration/adaptedAdministrationComponents";
import CalendarSheet from "components/appointments/CalendarSheet";
import ExternalIdComponent from "../components/rundschreiben/ExternalIdComponent";
import { launchApp } from "../components/App";
import loadAndConfigureCKEditor from "../helpers/ckeditor/loadAndConfigureCKEditor";
import { setApplication } from "../helpers/setApplication";
import Datepicker from "../helpers/Datepicker";
import showCookieModal from "../helpers/showCookieModal";
import documentReady from "../helpers/documentReady";
import customLodashCompat from "../helpers/legacy/customLodashCompat";
import adaptBackboneView from "../helpers/legacy/adaptBackboneView";
import Bridge from "turbo/bridge.tsx";
import initializeCustomConfirm from "turbo/confirm_compat.js";
import { fetchApi } from "../helpers/reactQueryApi";
import ActivitystreamWidgetbar from "../components/widgetbars/ActivitystreamWidgetbar";
import ChannelListView from "../components/directory/channels/ChannelListView";
import StreamTargeting from "../components/activitystreams/composer/StreamTargeting";

adaptBackboneView.addComponents({
  "activitystreams/Composer": ActivityComposer,
  "activitystreams/ReplyComposer": wrapProvider(store)(ReplyComposer),
  "activitystreams/StreamTargeting": StreamTargeting,
  "calendars/MonthSheetView": CalendarSheet,
  "rundschreiben/ExternalIdComponent": ExternalIdComponent,
  "widgetbars/ActivitystreamWidgetbar": ActivitystreamWidgetbar,
  "directory/ChannelListView": ChannelListView,
  ...adaptedFileComponents,
  ...adaptedSharedComponents,
  ...adaptedAppCreatorComponents,
  ...adaptedAdministrationComponents,
});

// Backwards compat
window.jQuery = window.$ = jQuery;
window._ = lodash;
window._compat = customLodashCompat;
window.hljs = hljs;
window.adaptBackboneView = adaptBackboneView;
window.loadAndConfigureCKEditor = loadAndConfigureCKEditor;
window.moment = moment;
window.ClipboardJS = ClipboardJS;
window.Datepicker = Datepicker;

// check if is access by app and do some configuration if so
const regex = new RegExp(/tixxt App/gm);
window.isApp = regex.test(window.navigator.userAgent);

window.bridge = {
  prompt: (message, defaultValue, callback) => {
    const userInput = prompt(message, defaultValue);

    if (callback) {
      callback(userInput);
    }
  },
  confirm: (message, callback) => {
    const confirmed = confirm(message);

    if (confirmed) {
      callback();
    }
  },
};

documentReady(() => initializeCustomConfirm());

// Global helper stuff
if (window.isApp) {
  window.bridge = Bridge;

  document.addEventListener("turbo:load", Bridge.configureLayout);
}

window.Application = setApplication();
window.showCookieModal = showCookieModal;
window.fetchApi = fetchApi;

launchApp();
