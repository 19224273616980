import React from "react";
import AppointmentForm from "components/appointments/form/AppointmentForm";
import { useSearchParams } from "helpers/tixxt-router";
import PageTitle from "components/layout/PageTitle";
import moment from "moment";

export default function AppointmentNewPage() {
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const calendarId = searchParams.get("calendar_id");
  const startDate = moment(date)
    .set({ hour: moment().hour() })
    .add(1, "hour")
    .startOf("hour");

  return (
    <>
      <PageTitle title={I18n.t("js.calendars.new_appointment")} />
      <AppointmentForm
        date={date ? { start: startDate.format(), all_day: false } : null}
        calendarId={calendarId}
      />
    </>
  );
}
