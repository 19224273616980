import React, { useCallback } from "react";
import Async from "react-select/async";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import {
  formatOptionLabel,
  isOptionDisabled,
  noOptionsMessage,
} from "components/shared/DirectoryPicker/optionRendering";
import {
  OptionGroup,
  PermissionPickableWithDisabled,
  ValueType,
} from "components/shared/DirectoryPicker/types";
import { isEmpty } from "lodash";
import { DirectoryEntry, fetchDirectory } from "components/directory/api";
import {
  disabledOptions,
  expandMembershipRoles,
  groupOptions,
} from "components/shared/DirectoryPicker/optionLoading";

function useDirectoryFetcher(
  endpoint = "/directory",
  query = {},
  extraOptions?: (
    inputValue: string,
  ) =>
    | Promise<PermissionPickableWithDisabled[]>
    | PermissionPickableWithDisabled[],
) {
  return useCallback(
    async function directoryFetcher(
      inputValue: string,
    ): Promise<OptionGroup[]> {
      try {
        let entries: DirectoryEntry[];
        if (isEmpty(inputValue)) entries = [];
        else
          entries = (
            await fetchDirectory(
              {
                ...query,
                q: inputValue,
              },
              endpoint,
            )
          ).entries;

        const extraOptionValues = extraOptions
          ? await extraOptions(inputValue)
          : [];

        return expandMembershipRoles(
          groupOptions([
            ...extraOptionValues,
            ...disabledOptions(inputValue, endpoint),
            ...entries,
          ]),
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [endpoint, query],
  );
}

export type DirectoryPickerProps = {
  value?: ValueType[];
  onChange?: (
    newValue: MultiValue<ValueType> | SingleValue<ValueType>,
    actionMeta: ActionMeta<ValueType>,
  ) => void;
  multi?: boolean;
  placeholder?: string;
  endpoint?: string;
  query?: { [param: string]: string | boolean };
  showDefaultOptions?: boolean;
  disabled?: boolean;
  extraOptions?: (
    inputValue: string,
  ) =>
    | Promise<PermissionPickableWithDisabled[]>
    | PermissionPickableWithDisabled[];
};

/**
 * A UI element that enables the selection of users or users+groups, optionally with membership roles, on the network or group level.
 *
 * @param {Object} props
 * @param {string} props.endpoint Defaults to /directory, could also be /directory/members or /directory/:group_category_slug or /directory/channels
 * @param {string} props.query Extra query for the request to endpoint, make sure that this is memoized
 */
export default React.forwardRef(function DirectoryPicker(
  {
    value,
    onChange,
    multi,
    placeholder,
    endpoint,
    query,
    extraOptions,
    showDefaultOptions = true,
    disabled,
  }: DirectoryPickerProps,
  // react-hook-form adds ref prop which we dont need but leads to warning unless we accept it with React.forwardRef
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _ref,
) {
  const loadDirectoryOptions = useDirectoryFetcher(
    endpoint,
    query,
    extraOptions,
  );

  return (
    <Async<ValueType, boolean, OptionGroup>
      cacheOptions
      loadOptions={loadDirectoryOptions}
      formatOptionLabel={formatOptionLabel}
      isMulti={multi}
      placeholder={placeholder || placeholderForEndpoint(endpoint)}
      isOptionDisabled={isOptionDisabled}
      defaultOptions={showDefaultOptions}
      noOptionsMessage={noOptionsMessage}
      getOptionValue={getOptionValue}
      value={value}
      onChange={onChange}
      className="form-select-container"
      classNamePrefix="form-select"
      isDisabled={disabled}
      unstyled
    />
  );
});

// This representation is only used internally in react-select
function getOptionValue(entry: ValueType) {
  return `${entry.type}/${entry.id}`;
}

function placeholderForEndpoint(endpoint: undefined | string): string {
  switch (endpoint) {
    case "/directory/members":
      return I18n.t("js.member_select.placeholder");
    case "/directory/channels":
      return I18n.t("js.channel_select.placeholder");
    default:
      return I18n.t("js.audience_select.placeholder");
  }
}
