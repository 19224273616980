import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { compact, find, get, map, includes } from "lodash";
import { selectFileProfileColumnFields } from "../../../selectors/propertySets";
import propertySetStyles from "../../shared/propertySets/propertySets.module.css";
import PropertyShow from "../../appCreator/properties/PropertyShow";
import Sorter from "../../shared/tables/Sorter";
import { usePropertySet } from "../../shared/propertySets/api";

const isSortable = (property) =>
  includes(["date", "text", "selection"], property.type);

// Hook to build extra columns for file profile property set
// identified by given contextId and contextType
const useExtraColumns = ({ contextId, contextType }) => {
  const columnFields = useSelector(selectFileProfileColumnFields);

  const { isLoading: fileLoading, data: fileProfilePropertySet } =
    usePropertySet({
      name: "file_profile",
      contextId,
      contextType,
    });
  const { isLoading: folderLoading, data: folderProfilePropertySet } =
    usePropertySet({
      name: "folder_profile",
      contextId,
      contextType,
    });

  return useMemo(() => {
    const properties = (get(fileProfilePropertySet, "properties") || []).concat(
      get(folderProfilePropertySet, "properties"),
    );
    const columnProperties = compact(
      map(columnFields, (name) => find(properties, { name })),
    );

    const extraColumns = map(columnProperties, (property) => {
      function Header({ handleSort, sortBy }) {
        return (
          <th className={`col-profile-${property.name}`}>
            <Sorter
              attribute={`profile.${property.name}`}
              label={property.label}
              handleSort={handleSort}
              sortBy={sortBy}
              disabled={!isSortable(property)}
            />
          </th>
        );
      }
      function Data({ profile }) {
        return (
          <td className={propertySetStyles.value}>
            {profile ? (
              <PropertyShow item={{ values: profile }} property={property} />
            ) : null}
          </td>
        );
      }
      Data.propTypes = { profile: PropTypes.object };
      return { Header, Data };
    });

    return { extraColumns, loading: folderLoading || fileLoading };
  }, [
    columnFields,
    fileProfilePropertySet,
    folderProfilePropertySet,
    folderLoading,
    fileLoading,
  ]);
};

export default useExtraColumns;
