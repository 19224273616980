import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { map, isArray, compact, debounce, castArray } from "lodash";
import { Field } from "redux-form";
import AsyncSelect from "react-select/async";

import styles from "./editFields.module.css";
import FieldError from "components/appCreator/items/form/FieldError";
import { useMutation } from "react-query";
import { stringify } from "query-string";
import { fetchApi } from "../../../../helpers/reactQueryApi";

const normalizeValue = (value) => {
  if (value === null) return null; // When nothing is selected
  if (isArray(value)) return map(value, normalizeValue);

  return {
    id: value.value,
    displayValue: value.label,
  };
};

const formatValue = (state) => {
  return map(
    compact(castArray(state)),
    ({ id, displayName, displayValue }) => ({
      value: id,
      label: displayName || displayValue,
    }),
  );
};

function SelectAdapter({
  input,
  required,
  id,
  disabled,
  className,
  multiple,
  meta: { error },
}) {
  const { mutate: loadOptions } = useMutation(
    ({ q }) => fetchApi(`/members?${stringify({ q })}`),
    {
      onSuccess: (data, variables) => {
        variables.callback(formatValue(data));
      },
    },
  );

  const debouncedLoadOptions = useCallback(
    debounce(
      (value, callback) => loadOptions({ q: value + "*", callback }),
      375,
    ),
    [loadOptions],
  );

  let props = {
    ...input,
    // workaround for mobile,
    // see ticket #9930 or issue https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
    onBlur: (e) => e.preventDefault(),
    isClearable: !required,
    isDisabled: disabled,
    inputId: id,
    placeholder: I18n.t("js.member_select.placeholder"),
    noOptionsMessage: () => I18n.t("plugins.select2.no_match"),
    className,
    loadOptions: debouncedLoadOptions,
    isMulti: multiple,
    styles: { menu: (base) => ({ ...base, zIndex: 999 }) },
  };

  return (
    <Fragment>
      <div className="remove-input-txt-border">
        <AsyncSelect
          {...props}
          className="form-select-container"
          classNamePrefix="form-select"
          unstyled
        />
      </div>
      {error && <FieldError error={error} />}
    </Fragment>
  );
}
SelectAdapter.propTypes = {
  input: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({ error: FieldError.propTypes.error }),
};

function MembershipField({ name, required, disabled, id, multiple }) {
  return (
    <Field
      component={SelectAdapter}
      name={name}
      required={required}
      disabled={disabled}
      normalize={normalizeValue}
      format={formatValue}
      multiple={multiple}
      id={id}
      props={{
        className: `${styles.Membership} property-${name}`,
      }}
    />
  );
}
MembershipField.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  ...SelectAdapter.propTypes,
};

export default MembershipField;
