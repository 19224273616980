import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive } from "@fortawesome/pro-light-svg-icons";

export default function DangerZone({
  title,
  body,
  actions,
}: {
  title: string;
  body: React.ReactNode;
  actions: React.ReactNode;
}) {
  return (
    <div className="danger-zone mt-6">
      <h1 className="font-semibold text-xl my-6">{title}</h1>
      <div className="alert alert-danger flex gap-4">
        <div>
          <FontAwesomeIcon icon={faBoxArchive} size="5x" />
        </div>
        <div className="grow space-y-2">
          <p>{body}</p>
          <div className="flex gap-2">{actions}</div>
        </div>
      </div>
    </div>
  );
}
