import React from "react";
import { Control, UseFormRegister, useWatch } from "react-hook-form";

import Label from "components/shared/form/Label";
import Checkbox from "components/shared/form/fields/Checkbox";
import DateTimePicker from "components/shared/form/fields/DateTimePicker";

export default function ApplicationConfigurationField({
  register,
  control,
}: {
  register: UseFormRegister<any>;
  control: Control<any>;
}) {
  const withCosts = useWatch({
    control,
    name: "application_configuration.registration_with_costs",
  });
  const registrationRequired = useWatch({
    control,
    name: "application_configuration.registration_required",
  });

  return (
    <div className="control-group">
      <Label
        label={I18n.t(
          "js.calendars.appointment.application_configuration.label",
        )}
      />
      <div className="controls border-box flex flex-col gap-y-3 p-3">
        <div className="inline-controls">
          <Checkbox
            name="application_configuration.registration_with_costs"
            register={register}
            label={I18n.t(
              "js.calendars.application_configuration.registration_with_costs.label",
            )}
          />
        </div>
        {withCosts && (
          <div className="control-group items-center">
            <Label
              label={`${I18n.t(
                "js.calendars.application_configuration.costs.label",
              )} / ${I18n.t("js.currency_label")}`}
            />

            <div className="controls flex gap-2">
              <div className="w-32">
                <input
                  type="number"
                  {...register("application_configuration.costs")}
                  min="0"
                />
              </div>
              <div className="w-14">
                <input
                  type="text"
                  {...register("application_configuration.currency")}
                />
              </div>
            </div>
          </div>
        )}
        <div className="inline-controls">
          <Checkbox
            name="application_configuration.registration_required"
            register={register}
            label={I18n.t(
              "js.calendars.application_configuration.registration_required.label",
            )}
          />
        </div>
        {registrationRequired && (
          <DateTimePicker
            name="application_configuration.registration_deadline"
            label={I18n.t(
              "js.calendars.application_configuration.registration_deadline.label",
            )}
          />
        )}
        <div className="control-group items-center">
          <Label
            label={I18n.t(
              "js.calendars.application_configuration.ticket_url.label",
            )}
          />
          <div className="controls">
            <input
              type="url"
              placeholder="https://"
              {...register("application_configuration.url")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
