import { convertRoutes } from "components/tixxt/BackboneFeature";
import { RouteObject } from "helpers/tixxt-router";
import React from "react";
import AppointmentsPage from "components/appointments/AppointmentsPage";
import ManageCalendarsPage from "components/appointments/calendars/ManageCalendarsPage";
import SubscribeCalendarsPage from "components/appointments/calendars/SubscribeCalendarsPage";
import ParticipantsView from "components/appointments/participants/ParticipantsView";
import NewCalendarPage from "components/appointments/calendars/NewCalendarPage";
import EditCalendarPage from "components/appointments/calendars/EditCalendarPage";
import AppointmentNewPage from "components/appointments/AppointmentNewPage";
import AppointmentEditPage from "components/appointments/AppointmentEditPage";
import AppointmentPage from "components/appointments/AppointmentPage";
import AppointmentContactPage from "components/appointments/AppointmentContactPage";
import Invites from "components/appointments/InvitesPage";

const backboneRoutes: RouteObject[] = convertRoutes({
  feature: "Calendars",
  featureRoutes: {
    "calendars/my": "showMyCalendars",
    "calendars/shared": "showSharedCalendars",
  },
});

const reactRoutes: RouteObject[] = [
  { path: "/groups/:groupSlug/calendars", element: <AppointmentsPage /> },
  { path: "/appointments", element: <AppointmentsPage /> },
  { path: "/appointments/new", element: <AppointmentNewPage /> },
  { path: "/appointments/:id/edit", element: <AppointmentEditPage /> },
  {
    path: "/appointments/participating",
    element: <AppointmentsPage participating />,
  },
  {
    path: "/appointments/invites",
    element: <Invites />,
  },
  {
    path: "/appointments/:id",
    element: <AppointmentPage />,
  },
  {
    path: "/calendars/manage",
    element: <ManageCalendarsPage />,
  },
  {
    path: "/calendars/subscribe",
    element: <SubscribeCalendarsPage />,
  },
  {
    path: "/calendars/new",
    element: <NewCalendarPage />,
  },
  {
    path: "/calendars/:id/edit",
    element: <EditCalendarPage />,
  },
  {
    path: "/appointments/:id/participants",
    element: <ParticipantsView />,
  },
  {
    path: "/appointments/:id/contact",
    element: <AppointmentContactPage />,
  },
];

export default backboneRoutes.concat(reactRoutes);
