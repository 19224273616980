import { UseQueryResult, useMutation, useQuery, useQueries } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { SimpleMembership } from "../../@types";
import { Message } from "components/messages/@types";

export type TrimmedMessage = {
  id: string;
  created_at: string;
  author: SimpleMembership;
  to: [SimpleMembership] | SimpleMembership[];
  to_count: number;
  subject: string;
  plain_content: string;
  html_content: string;
  has_replies: boolean;
  has_attachments: boolean;
  i: {
    marked: boolean;
    read: boolean;
  };
  conversation_length: number;
};

type MessagesPayload = {
  messages: TrimmedMessage[];
  offset: number;
  limit: number;
  total: number;
  next_url: string | null;
};

export function useFetchMessages(
  filter: string,
  page: number,
  options = { keepPreviousData: true },
) {
  const query = `?limit=10&offset=${(page - 1) * 10}`;
  return useQuery<MessagesPayload>(
    ["messages", filter, page],
    () => fetchApi(`/api/v1/messages${filter ? `/${filter}${query}` : query}`),
    options,
  );
}

type CustomUseQueryResult<TData, TError> = UseQueryResult<TData, TError> & {
  isTranslating?: boolean;
};

export function useFetchMessage(
  messageId: string,
  messageLang: string | undefined,
  options = {},
): CustomUseQueryResult<Message, unknown> {
  const result = useQuery<Message>(
    ["message", messageId],
    () => fetchApi(`/api/v1/messages/${messageId}`),
    options,
  );

  const foreignMessageIds = getForeignMessageIds(result.data);
  const { data: translatedMessages, isLoading: isTranslating } =
    useTranslateMessages(foreignMessageIds, messageLang);

  if (result.data && translatedMessages) {
    const translatedMessage =
      translatedMessages.find((message) => message.id === result.data.id) || {};

    const translatedResult = {
      ...result,
      isTranslating: isTranslating,
      data: {
        ...result.data,
        ...translatedMessage,
        translated: translatedMessage.html_content,
        conversation: addTranslationsToConversation(
          result.data.conversation,
          translatedMessages,
        ),
      },
    };

    return translatedResult;
  }

  return { ...result, isTranslating };
}

function getForeignMessageIds(data?: Message) {
  let foreignMessageIds: string[] = [];

  if (data && Preload.current_member) {
    if (data.conversation) {
      const translatableMessages = data.conversation.filter(
        (item) => item.author.id != Preload.current_member?.id,
      );

      foreignMessageIds = translatableMessages.map((item) => {
        return item.id;
      });
    }

    if (data.author.id != Preload.current_member?.id) {
      foreignMessageIds.push(data.id);
    }
  }

  return foreignMessageIds;
}

type MessagesTranslationResult = {
  id: string;
  subject: string;
  content_rendered: string;
}[];

function addTranslationsToConversation(
  oldConversation: Message["conversation"],
  newConversation: MessagesTranslationResult,
) {
  if (!oldConversation) return [];

  return oldConversation.map((oldItem: Message) => {
    const newItem = newConversation.find(
      (newItem) => newItem.id === oldItem.id,
    );
    if (newItem) {
      return {
        ...oldItem,
        ...newItem,
        translated: true,
      };
    }

    return oldItem;
  });
}

export function useTranslateMessages(
  messageIds: string[],
  language: string | undefined,
) {
  const translationQueries = useQueries(
    messageIds.map((messageId) => ({
      queryKey: ["translated-message", messageId, language],
      queryFn: () => fetchApi(`/translations/${language}/message/${messageId}`),
      enabled: !!language,
      refetchOnWindowFocus: false,
    })),
  );

  const isTranslating = translationQueries.some(
    (query) => query.isLoading || query.isFetching,
  );

  const translatedMessages = translationQueries
    .map((query) => query.data)
    .filter(Boolean);

  return {
    data: translatedMessages,
    isLoading: isTranslating,
  };
}

export function useDeleteMessage(filter, messageId, options = {}) {
  return useMutation(() => {
    return fetchApi(
      `/api/v1/messages${filter === "sent" ? "/sent" : ""}/${messageId}`,
      { method: "DELETE" },
    );
  }, options);
}

export function useDeleteMessages(filter, options = {}) {
  return useMutation((messageIds: string[]) => {
    return fetchApi(
      `/api/v1/messages${filter === "sent" ? "/sent" : ""}/delete`,
      {
        method: "DELETE",
        body: { message_ids: messageIds },
      },
    );
  }, options);
}

export function useMarkMessageAsUnread(options = {}) {
  return useMutation(
    (messageId: string) =>
      fetchApi(`/api/v1/messages/${messageId}/read`, {
        method: "DELETE",
      }),
    options,
  );
}

export function useMarkMessageAsRead(options = {}) {
  return useMutation(
    (messageId: string) =>
      fetchApi(`/api/v1/messages/${messageId}/read`, {
        method: "POST",
      }),
    options,
  );
}

export function useMarkMessagesAsUnread(options = {}) {
  return useMutation(
    (messageIds: string[]) =>
      fetchApi(`/api/v1/messages/read`, {
        method: "DELETE",
        body: {
          message_ids: messageIds,
        },
      }),
    options,
  );
}

export function useMarkMessagesAsRead(options = {}) {
  return useMutation(
    (messageIds: string[]) =>
      fetchApi(`/api/v1/messages/read`, {
        method: "POST",
        body: {
          message_ids: messageIds,
        },
      }),
    options,
  );
}

export function useSendMessage(options = {}) {
  return useMutation(
    (body: Record<PropertyKey, unknown>) =>
      fetchApi(`/api/v1/messages`, {
        method: "POST",
        body,
      }),
    options,
  );
}

export function onError() {
  toastr.error(I18n.t("js.generic_network_error"));
}
