import classNames from "classnames";
import {
  findIndex,
  isEmpty,
  isEqual,
  last,
  map,
  pullAllBy,
  pullAt,
} from "lodash";
import React, { useEffect, useState } from "react";
import SortableTree, {
  changeNodeAtPath,
  getNodeAtPath,
  removeNodeAtPath,
  TreeItem,
} from "react-sortable-tree";
import { v4 as uuidv4 } from "uuid";

import { Item, MoreItem } from "../../../@types/appNavigation";
import {
  useAppNavigationItems,
  useUpdateAppNavigation,
} from "../../../hooks/administration/appNavigation";
import PageTitle from "../../layout/PageTitle";
import "../navigationManager.css";
import ItemButton from "../navigationManager/ItemButton";
import ItemForm from "./ItemForm";
import NodeTitle from "./NodeTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

function AppNavigationManager() {
  const { data, isLoading } = useAppNavigationItems();

  const [more, setMore] = useState<MoreItem>({
    options: { items: [] },
    type: "more",
  });
  const [treeData, setTreeData] = useState<(TreeItem | Item)[]>([]);
  const [activePath, setActivePath] = useState<(number | string)[]>([]);
  const { mutate: updateItems, isLoading: isSaving } = useUpdateAppNavigation();

  useEffect(() => {
    if (!isEmpty(treeData)) return;
    if (!data || isEmpty(data)) return;

    setTreeData(data);

    const moreCandidate = last(data);
    if (moreCandidate?.type == "more") setMore(moreCandidate);
  }, [data]);

  function removeItemAt(
    _e: unknown,
    path: (string | number)[],
    node: TreeItem,
  ) {
    setActivePath([]);
    const newTreeData = removeNodeAtPath({
      treeData: treeData,
      path,
      getNodeKey,
    });

    if (node.isEntryPoint) {
      changeEntryPoint(node, false, newTreeData);
    } else {
      setTreeData(newTreeData);
    }

    moveItemToMore(node);
  }

  function changeActiveNode(newNode: TreeItem) {
    const activeNode = getActiveNode();

    if (activeNode?.id !== newNode.id) {
      setActivePath([newNode.id]);

      if (newNode.isDefault) {
        removeItemFromMore(newNode);
      }

      if (activeNode?.isDefault) {
        moveItemToMore(activeNode);
      }
    }

    const newTreeData = changeNodeAtPath({
      treeData,
      path: activePath,
      getNodeKey,
      newNode,
    });

    if (activeNode?.isEntryPoint && newNode.type === "link") {
      changeEntryPoint(activeNode, false, newTreeData);
    } else {
      // set entry point if none of the other items is
      if (
        findIndex(
          newTreeData,
          (data) => data.isEntryPoint && data.id !== newNode.id,
        ) === -1
      ) {
        changeEntryPoint(newNode, true, newTreeData);
      } else {
        setTreeData(newTreeData);
      }
    }
  }

  function moveItemToMore(item: TreeItem) {
    if (item?.isDefault) {
      const newMore = more;
      newMore.options.items.push({ ...item, isEntryPoint: false });

      setMore(newMore);
    }
  }

  function removeItemFromMore(item: TreeItem) {
    const newMore = more;

    pullAllBy(newMore.options.items, [{ id: item.id }], "id");

    setMore(newMore);
  }

  function addItem() {
    const data = treeData;
    const more = pullAt(data, [treeData.length - 1]);

    const newItem = {
      id: uuidv4(),
      label: I18n.t("js.administration.app_navigation_items.edit.new_link"),
      type: "link",
      icon: "link",
      isEntryPoint: false,
      isDefault: false,
      options: { url: "" },
    };

    setTreeData([...data, newItem, ...more]);

    const path = [newItem.id];
    setActivePath(path);
  }

  function itemButtons(node: TreeItem, path: (number | string)[]) {
    const buttons: JSX.Element[] = [];

    if (node.type !== "more") {
      buttons.push(
        <ItemButton
          onClick={(e) => removeItemAt(e, path, node)}
          icon="fa-regular fa-trash"
          buttonClass="btn-danger"
        />,
      );
    }

    return buttons;
  }

  const getNodeKey = ({ node }: { node: TreeItem }) => node.id;

  function getActiveNode() {
    const node = getNodeAtPath({
      treeData: treeData,
      path: activePath,
      getNodeKey,
    });
    return node && node.treeIndex > -1 ? node.node : null;
  }

  function changeEntryPoint(
    item: TreeItem,
    checked: boolean,
    changedTreeData?: TreeItem[],
  ) {
    const newTreeData = map(changedTreeData || treeData, (data, index) => ({
      ...data,
      isEntryPoint:
        checked && item.id === data.id // set item as entry point if it is checked
          ? true
          : !checked &&
              index ===
                findIndex(
                  changedTreeData || treeData,
                  (data) =>
                    data.type !== "link" &&
                    data.type !== "more" &&
                    data.id !== item.id,
                ) // set first item (not link or more) as entry point if item is unchecked
            ? true
            : false, // reset all others to false (to avoid more than one entry point)
    }));

    setTreeData(newTreeData);
  }

  function submit() {
    updateItems({ body: { items: treeData } });
  }

  const hasNoEntryPointItem =
    findIndex(treeData, { isEntryPoint: true }) === -1;

  const activeNode = getActiveNode();

  return (
    <div>
      <PageTitle
        title={I18n.t("js.administration.app_navigation_items.edit.page_title")}
      />
      <div className="btn-toolbar justify-end mb-4">
        <button
          className="btn btn-success"
          type="submit"
          onClick={submit}
          disabled={treeData.length < 5 || hasNoEntryPointItem}
        >
          {I18n.t("js.administration.navigation_items.edit.save")}
        </button>
      </div>
      {isLoading ? (
        <p>
          <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />
          {I18n.t("js.administration.app_navigation_items.loading")}
        </p>
      ) : (
        <div className="row">
          <div className="navigation-manager">
            <div className="navigation-column">
              <SortableTree
                treeData={treeData}
                onChange={(treeData: Item[]) => setTreeData(treeData)}
                getNodeKey={getNodeKey}
                isVirtualized={false}
                maxDepth={1}
                canDrag={({ node }) => node.type !== "more"}
                onMoveNode={(e) => setActivePath(e.path)}
                generateNodeProps={({ node, path }) => ({
                  title: NodeTitle,
                  key: node.type,
                  onClick: () => setActivePath(path),
                  buttons: itemButtons(node, path),
                  className: classNames(
                    {
                      "selected move-item": isEqual(activePath, path),
                      "not-empty-field": node.label || isSaving,
                      "empty-field": !node.label && !isSaving,
                    },
                    "content-item",
                  ),
                })}
              />
              <button
                className="btn btn-primary add-new-item-button"
                disabled={treeData.length === 5 || isSaving}
                onClick={addItem}
              >
                <i className="fa fa-plus" />
                {I18n.t("js.administration.navigation_items.edit.add_link")}
              </button>
              {hasNoEntryPointItem && (
                <p className="hint">
                  {I18n.t(
                    "js.administration.app_navigation_items.edit.hint_entry_point_needed",
                  )}
                </p>
              )}
              {treeData.length < 5 && (
                <p className="hint">
                  {I18n.t(
                    "js.administration.app_navigation_items.edit.hint_five_navigation_items_needed",
                  )}
                </p>
              )}
            </div>
            <div className="input-column">
              {activeNode && (
                <ItemForm
                  item={activeNode as any}
                  unusedDefaults={more?.options?.items}
                  changeActiveNode={changeActiveNode}
                  changeEntryPoint={changeEntryPoint}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AppNavigationManager;
