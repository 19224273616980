import React, { useContext } from "react";
import { Appointment } from "../../../@types/appointments";
import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import AppointmentDate from "components/appointments/details/AppointmentDate";
import AppointmentLocation from "components/appointments/details/AppointmentLocation";
import AppointmentMeeting from "components/appointments/details/AppointmentMeeting";
import { isEmpty } from "lodash";
import AppointmentDescription from "components/appointments/details/AppointmentDescription";

type AppointmentsWidgetProps = {
  appointment?: Appointment;
};

export default function AppointmentsWidget({
  appointment,
}: AppointmentsWidgetProps) {
  const { w } = useContext(WidgetContext);
  if (!appointment) return null;

  return (
    <div className="widget appointments-widget p-4 flex flex-col gap-4 overflow-hidden">
      <div className="flex gap-8">
        {w > 1 ? (
          <div className="overflow-hidden rounded-full self-start shrink-0 hidden sm:block">
            <img
              className="object-cover object-center h-36 w-36"
              src={
                appointment.event_image?.large_thumb ||
                Assets["appointments/event_image_default.jpg"]
              }
              alt={""}
              aria-hidden
            />
          </div>
        ) : null}
        <div className="grow flex flex-col gap-4 text-sm">
          <h4 className="text-lg font-bold">
            <a href={`/appointments/${appointment.id}`}>{appointment.name}</a>
          </h4>
          <AppointmentDate
            date={appointment.date}
            isRecurring={false} // @todo:
            recurrence_pattern={appointment.recurrence_pattern}
            next_occurrence_date={appointment.next_occurrence_date}
            next_occurrence_url={appointment.next_occurrence_url}
          />
          <AppointmentLocation {...appointment.location} showMap={false} />
          {appointment.meeting && appointment.meeting.join_url && (
            <AppointmentMeeting
              date={appointment.date}
              meeting={appointment.meeting}
              appointmentName={appointment.name}
              canStartMeeting={appointment.can.start_meeting}
              appointmentId={appointment.id}
            />
          )}
        </div>
      </div>
      <AppointmentDescription
        description={
          appointment.description_rendered || appointment.description
        }
        isRendered={!isEmpty(appointment.description_rendered)}
        translated={false}
      />
    </div>
  );
}
