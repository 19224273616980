import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  ApplicationConfiguration,
  Appointment,
  AttendeeConfiguration,
  TeamsConfig,
} from "../../../@types/appointments";
import TextInput from "components/shared/form/fields/TextInput";
import TextEditor from "components/shared/form/fields/TextEditor";
import Select from "components/shared/form/fields/Select";
import {
  compact,
  concat,
  filter,
  find,
  forEach,
  isEmpty,
  keys,
  map,
  omit,
  orderBy,
  pull,
  some,
  startsWith,
  toLower,
} from "lodash";
import {
  useCalendars,
  useCreateAppointment,
  useUpdateAppointment,
} from "components/appointments/api";
import moment from "moment";
import RecurringSelectField from "components/appointments/form/RecurringSelectField";
import LocationField from "components/appointments/form/LocationField";
import ParticipantsField from "components/appointments/form/ParticipantsField";
import TeamsConfigField from "components/appointments/form/TeamsConfigField";
import FileUploadField from "components/shared/form/fields/FileUploadField";
import EventSettings from "components/appointments/form/EventSettings";
import { Category } from "../../../@types/appointmentCategories";
import Checkbox from "components/shared/form/fields/Checkbox";
import ModifiedRecurringAppointmentHint from "components/appointments/form/ModifiedRecurringAppointmentHint";
import Label from "components/shared/form/Label";
import usePublishAllVolatile from "helpers/files/usePublishAllVolatile";
import FormActions from "components/shared/form/fields/FormActions";
import { ValueType } from "components/shared/DirectoryPicker/types";
import DateRangeSelectField from "components/appointments/form/DateRangeSelectField";
import MeetingRoomSelectField from "components/shared/MeetinRoomSelectField";

export type AppointmentFormData = {
  name: string;
  description: string;
  calendars: { value: string; label: string }[];
  date: {
    all_day: boolean;
    start: string;
    end?: string;
    same_day: boolean;
    time_zone: { label: string; value: string };
  };
  recurrence_pattern: {
    recurrence_frequency: "never" | "daily" | "weekly" | "monthly" | "yearly";
    recurrence_end_time: string | null;
  };
  meeting?: {
    room_id: string;
    reservation_id: string;
    room_name: string;
  } | null;
  location: {
    address1: string | null;
    address2: string | null;
    city: string | null;
    country: string | null;
    link: string | null;
    maps_url: string | null;
    meeting_link_type: string | null;
    name: string | null;
    zip: string | null;
  };
  participants: ValueType[];
  teams_configuration: TeamsConfig;
  event_files: File[];
  event_image: File | File[];
  category: (Category & { value?: string }) | null;
  consultants: string;
  organizer: string;
  contacts: ValueType[];
  attendee_configuration: AttendeeConfiguration;
  application_configuration: ApplicationConfiguration;
  invite_consumers: boolean;
  post_to_stream: boolean;
  is_event: boolean;
};

type AppointmentFormArgs = {
  appointment?: Appointment;
  date?: { start: string; all_day: boolean } | null;
  calendarId?: string | null;
};

export default function AppointmentForm({
  appointment,
  date,
  calendarId,
}: AppointmentFormArgs) {
  const isRecurring = appointment?.type === "recurring_appointment" || false;
  const isNormal = appointment?.type === "appointment" || false;
  const isModifiedRecurring =
    (appointment?.type === "virtual_appointment" &&
      startsWith(appointment?.id, "r-")) ||
    false;

  const isExisting = !!appointment?.id;

  const { data: calendars } = useCalendars();

  const publishAllVolatile = usePublishAllVolatile();

  const { mutate: createAppointment, isLoading: isCreating } =
    useCreateAppointment({
      onSuccess,
      onError,
    });
  const { mutate: updateAppointment, isLoading: isUpdating } =
    useUpdateAppointment({
      onSuccess,
      onError,
    });

  const [filesLoading, setFilesLoading] = useState(false);

  const calendarSelectRef = useRef<HTMLDivElement>(null);
  const dateRangeRef = useRef<HTMLDivElement>(null);

  const defaultStartDate = appointment?.date.start
    ? moment(appointment?.date.start)
    : date
      ? moment(date?.start)
      : moment().add(1, "hour").startOf("hour");

  const defaultEndDate = appointment?.date.end
    ? moment(appointment?.date.end)
    : isExisting
      ? null
      : moment(defaultStartDate).add(1, "hour");

  const methods = useForm<AppointmentFormData>({
    defaultValues: {
      name: appointment?.name || "",
      description: appointment?.description || "",
      calendars: appointment
        ? compact(
            map(appointment?.calendar_ids, (calendar_id) => {
              const calendar = find(calendars, { id: calendar_id });

              return calendar && { value: calendar_id, label: calendar.name };
            }),
          )
        : [],
      date: {
        start: defaultStartDate.format(),
        end: defaultEndDate?.format(),
        all_day: appointment?.date.all_day || date?.all_day,
        time_zone: {
          label: `${appointment?.date.time_zone || moment.tz.guess()} (${moment
            .tz(
              appointment?.date.start || moment().format(),
              appointment?.date.time_zone || moment.tz.guess(),
            )
            .format("z")})`,
          value: appointment?.date.time_zone || moment.tz.guess(),
        },
        same_day: appointment?.date.same_day || true,
      },
      recurrence_pattern: appointment?.recurrence_pattern || {
        recurrence_frequency: "never",
      },
      meeting: appointment?.meeting,
      location: appointment?.location,
      participants: appointment?.participants,
      event_files: appointment?.event_files || [],
      teams_configuration: appointment?.teams_configuration,
      event_image: appointment?.event_image ? [appointment?.event_image] : [],
      category: appointment?.category || {
        value: "-1",
        label: I18n.t("js.calendars.appointment.category.no_selection"),
      },
      consultants: appointment?.consultants,
      contacts: appointment?.contacts.map((c) => ({ ...c, type: "member" })),
      organizer: appointment?.organizer,
      attendee_configuration: appointment?.attendee_configuration || {
        user_can_opt: false,
      },
      application_configuration: appointment?.application_configuration || {
        currency: "€",
      },
      invite_consumers: false,
      is_event: appointment?.is_event || false,
    },
  });

  const { handleSubmit, control, register, setValue } = methods;

  const selectedDate = useWatch({ control, name: "date" });
  const selectedEventFiles = useWatch({ control, name: "event_files" });
  const selectedEventImage = useWatch({ control, name: "event_image" });

  function onSuccess(newAppointment: Appointment) {
    location.replace(`/appointments/${newAppointment.id}`);
  }

  function onError() {
    toastr.error(I18n.t("generic_error"));
  }

  function onValidationError(errors) {
    forEach(document.getElementsByClassName("field-error"), (element) => {
      element?.classList.remove("field-error");
    });

    forEach(errors, (error, name) => {
      if (name === "calendars") {
        calendarSelectRef?.current?.classList.add("field-error");
      } else {
        error?.ref?.classList.add("field-error");
      }
      toastr.error(error?.message || I18n.t("generic_error"));
    });
  }

  useEffect(() => {
    setFilesLoading(
      some(concat(selectedEventFiles, selectedEventImage), {
        state: "uploading",
      }),
    );
  }, [selectedEventFiles, selectedEventImage]);

  useEffect(() => {
    if (calendars) {
      let defaultCalendars: { value: string; label: string }[] = [];

      if (appointment)
        defaultCalendars = compact(
          map(appointment?.calendar_ids, (calendar_id) => {
            const calendar = find(calendars, { id: calendar_id });

            return calendar && { value: calendar_id, label: calendar.name };
          }),
        );

      if (calendarId) {
        const preSelectedCalendar = find(calendars, { id: calendarId });
        if (preSelectedCalendar)
          defaultCalendars = [
            {
              value: preSelectedCalendar.id,
              label: preSelectedCalendar.name,
            },
          ];
      }
      setValue("calendars", defaultCalendars);
    }
  }, [calendars]);

  async function submit(data: AppointmentFormData) {
    forEach(document.getElementsByClassName("field-error"), (element) => {
      element?.classList.remove("field-error");
    });

    if (
      data.date.end &&
      moment(data.date.end).isBefore(moment(data.date.start))
    ) {
      toastr.error(I18n.t("js.calendars.validation_errors.end_lt_start"));
      dateRangeRef?.current?.classList.add("field-error");
      dateRangeRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    let newAppointmentData: any = { ...data };

    if (data.category?.value === "-1") {
      newAppointmentData.category = null;
    } else {
      newAppointmentData.category = data.category?.value;
    }

    if (!Preload.current_network.can.create_meetings) {
      newAppointmentData.meeting = null;
    }

    newAppointmentData["calendar_ids"] = map(data.calendars, "value");
    newAppointmentData["membership_ids_to_invite"] = map(
      filter(data.participants, { type: "member" }),
      "id",
    );
    newAppointmentData["membership_role_ids_to_invite"] = map(
      filter(data.participants, { type: "membership_role" }),
      "id",
    );
    newAppointmentData["group_ids_to_invite"] = map(
      filter(data.participants, { type: "group" }),
      "id",
    );
    newAppointmentData["contact_ids"] = map(data.contacts, "id");

    if (!isEmpty(selectedEventFiles)) {
      const result = await publishAllVolatile({
        values: {
          event_files: selectedEventFiles,
        },
        storageDirectory: "files",
      });

      newAppointmentData.event_files = map(result.event_files, (file) =>
        file.state
          ? { ...file, file_object_id: file.id, id: null, type: "file" }
          : file,
      );
    } else {
      newAppointmentData.event_files = [];
    }

    if (!isEmpty(selectedEventImage)) {
      const result = await publishAllVolatile({
        values: {
          event_image: selectedEventImage,
        },
        storageDirectory: "images",
      });
      newAppointmentData.event_image = result.event_image[0]
        ? result.event_image[0].state
          ? omit(
              {
                ...result.event_image[0],
                file_object_id: result.event_image[0].id,
              },
              "id",
            )
          : result.event_image[0]
        : {};
    } else {
      newAppointmentData.event_image = null;
    }

    newAppointmentData = omit(newAppointmentData, [
      "calendars",
      "participants",
      "contacts",
    ]);

    newAppointmentData = {
      ...newAppointmentData,
      date: {
        ...newAppointmentData.date,
        time_zone: newAppointmentData.date.time_zone.value,
      },
    };

    appointment
      ? updateAppointment({
          appointmentId: appointment.id,
          ...newAppointmentData,
        })
      : createAppointment(newAppointmentData);
  }

  return (
    <FormProvider {...methods}>
      <form
        className="form-horizontal mb-6"
        onSubmit={handleSubmit(submit, onValidationError)}
      >
        {appointment && isModifiedRecurring && (
          <ModifiedRecurringAppointmentHint appointmentId={appointment.id} />
        )}
        <TextInput
          label={I18n.t("js.calendars.calendar.name")}
          name="name"
          register={register}
          placeholder={I18n.t("js.calendars.appointment.name.placeholder")}
          required={I18n.t("js.calendars.validation_errors.name_empty")}
        />
        <TextEditor
          name="description"
          control={control}
          label={I18n.t("js.calendars.appointment.description.label")}
          placeholder={I18n.t(
            "js.calendars.appointment.description.placeholder",
          )}
        />
        {!isModifiedRecurring && (
          <div ref={calendarSelectRef}>
            <Select
              name="calendars"
              label={I18n.t("js.calendars.appointment.calendars.label")}
              options={orderBy(
                map(
                  filter(calendars, (cal) => cal.can.create_appointments),
                  (calendar) => ({
                    value: calendar.id,
                    label: calendar.name,
                  }),
                ),
                (cal) => toLower(cal.label),
                "asc",
              )}
              placeholder={I18n.t("calendars.calendars_select.placeholder")}
              control={control}
              multi
              required={I18n.t(
                "js.calendars.validation_errors.calendars_empty",
              )}
            />
          </div>
        )}
        <div ref={dateRangeRef}>
          <DateRangeSelectField
            label={I18n.t("js.calendars.appointment.date_range.label")}
            name="date"
            required
          />
        </div>
        {!isModifiedRecurring && !isNormal && (
          <RecurringSelectField
            label={I18n.t("js.calendars.appointment.recurrence_pattern.label")}
            name="recurrence_pattern"
            control={control}
            startDate={selectedDate.start}
            required
          />
        )}
        {Preload.current_network.can.create_meetings &&
          !isRecurring &&
          !isModifiedRecurring && (
            <MeetingRoomSelectField
              dateRange={{
                start: selectedDate.start,
                end: selectedDate.end,
              }}
              control={control}
            />
          )}
        {Preload.current_member?.can?.see_teams_event_options &&
          !isRecurring &&
          !isModifiedRecurring && (
            <TeamsConfigField
              register={register}
              control={control}
              setValue={setValue}
            />
          )}
        {!isModifiedRecurring && (
          <LocationField
            register={register}
            shouldShowLocationDetails={some(
              pull(keys(appointment?.location), "name"),
              (key) => !isEmpty(appointment?.location[key]),
            )}
          />
        )}
        {!appointment && (
          <ParticipantsField control={control} register={register} />
        )}
        {!isModifiedRecurring && (
          <FileUploadField
            control={control}
            name={"event_files"}
            label={I18n.t("js.calendars.appointment.files.label")}
            options={{
              multiple: true,
              selectExisting: true,
            }}
            hint={I18n.t(
              "js.calendars.appointment.files.attachment_disclaimer",
            )}
          />
        )}
        {!isModifiedRecurring && (
          <EventSettings
            control={control}
            register={register}
            setValue={setValue}
          />
        )}
        <div className="control-group">
          <Label label={I18n.t("js.calendars.appointment.activity.label")} />
          <div className="controls border-box p-3">
            <Checkbox
              name="post_to_stream"
              register={register}
              label={I18n.t("js.calendars.appointment.post_to_stream")}
            />
          </div>
        </div>
        <FormActions
          onCancel={(e) => {
            e.preventDefault();
            window.location.pathname = appointment
              ? `/appointments/${appointment.id}`
              : "/appointments";
          }}
          saveDisabled={isUpdating || isCreating || filesLoading}
        />
      </form>
    </FormProvider>
  );
}
