import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  compact,
  difference,
  endsWith,
  get,
  groupBy,
  includes,
  isEmpty,
  map,
  every,
  some,
} from "lodash";
import { useQuery } from "react-query";

import FolderSelectModal from "./FolderSelectModal";
import preventDefault from "helpers/components/preventDefault";
import useItemBatchSelection from "../shared/hooks/useItemBatchSelection";
import Modal from "../shared/Modal";
import DefaultFolderRow from "./table/FolderRow";
import DefaultFileRow from "./table/FileRow";
import { useMoveFilesAndFolders, useRemoveFilesAndFolders } from "./api";

function BatchActions({
  children,
  contents,
  currentFolder,
  onRemoveItems,
  onMoveItems,
}) {
  const headerRef = useRef(null);
  const [isHoveringHeader, setIsHoveringHeader] = useState(false);

  useEffect(() => {
    if (headerRef.current !== null) {
      headerRef.current.onmouseover = () => setIsHoveringHeader(true);
      headerRef.current.onmouseleave = () => setIsHoveringHeader(false);
    }
  }, [headerRef.current]);

  const ids = useRef([]);
  ids.current = compact(
    map(contents, (c) =>
      !includes(["activitystream_folder", "root_folder"], c.type) ? c.id : null,
    ),
  );

  const [selectedIds, selectIds] = useState([]);

  const allSelected = difference(ids.current, selectedIds).length === 0;

  const { mutate: move, isLoading: isMoving } = useMoveFilesAndFolders({
    onSuccess: (data, { targetFolderId }) => {
      selectIds([]);
      onMoveItems(targetFolderId);
    },
  });

  const { mutate: remove, isLoading: isRemoving } = useRemoveFilesAndFolders({
    onSuccess: () => {
      onRemoveItems(selectedIds);
      selectIds([]);
    },
  });

  const onSelectItem = useItemBatchSelection(ids, selectedIds, selectIds);

  const selectAll = useCallback((e) => {
    if (!e.target.checked) {
      selectIds([]);
    } else {
      selectIds(ids.current);
    }
  }, []);

  const selectedItemIdsByType = groupBy(
    compact(
      map(contents, (content) =>
        includes(selectedIds, content.id)
          ? {
              type: content.type,
              id: content.id,
              canDestroy: content.can.destroy,
              finalized: !!content.finalizedAt,
              locked: !!content.lockedById,
            }
          : null,
      ),
    ),
    "type",
  );

  const removeSelected = async () => {
    window.bridge.confirm(
      I18n.t("js.files.batch_actions.delete_confirm"),
      () => {
        const requests = [];
        if (!isEmpty(selectedItemIdsByType.file)) {
          requests.push({
            type: "files",
            body: {
              file_ids: map(selectedItemIdsByType.file, (f) => f.id),
            },
          });
        }
        if (!isEmpty(selectedItemIdsByType.folder)) {
          requests.push({
            type: "folders",
            body: {
              folder_ids: map(selectedItemIdsByType.folder, (f) => f.id),
            },
          });
        }

        remove(requests);
      },
    );
  };

  const moveSelected = (targetFolder) => {
    const requests = [];

    if (!isEmpty(selectedItemIdsByType.file)) {
      requests.push({
        type: "files",
        body: {
          file_ids: map(selectedItemIdsByType.file, (f) => f.id),
          target_folder_id: targetFolder.id,
          source_folder_id: currentFolder.id,
        },
      });
    }
    if (!isEmpty(selectedItemIdsByType.folder)) {
      requests.push({
        type: "folders",
        body: {
          folder_ids: map(selectedItemIdsByType.folder, (f) => f.id),
          target_folder_id: targetFolder.id,
        },
      });
    }

    move({ targetFolderId: targetFolder.id, requests });
  };

  const rowProps = (item) => {
    const props = {
      isSelected: includes(selectedIds, item.id),
      showCheckbox: !isEmpty(selectedIds),
    };

    if (endsWith(item.type, "folder")) {
      return {
        ...props,
        disableSelection: includes(
          ["activitystream_folder", "root_folder"],
          item.type,
        ),
        onSelectFolder: onSelectItem,
      };
    } else {
      return {
        ...props,
        onSelectFile: onSelectItem,
      };
    }
  };

  const selectAllCheckbox = useMemo(
    () => (
      <td className="col-checkbox p-2 text-center">
        <input
          type="checkbox"
          checked={allSelected}
          onChange={selectAll}
          className={
            isHoveringHeader || !isEmpty(selectedIds) ? "" : "invisible"
          }
        />
      </td>
    ),
    [allSelected, selectAll, isHoveringHeader, selectedIds],
  );

  const childProps = {
    selectedIds,
    onSelectItem,
    headerRef,
    selectAllCheckbox,
    rowProps,
  };

  return (
    <>
      {!isEmpty(selectedIds) ? (
        <Toolbar
          currentFolder={currentFolder}
          selectedItemIds={selectedIds}
          selectedItemAndFolderIds={selectedItemIdsByType}
          removeSelected={removeSelected}
          moveSelected={moveSelected}
        />
      ) : null}
      {isMoving && (
        <Modal static>
          <Modal.Spinner label={I18n.t("js.files.batch_actions.is_moving")} />
        </Modal>
      )}
      {isRemoving && (
        <Modal static>
          <Modal.Spinner label={I18n.t("js.files.batch_actions.is_removing")} />
        </Modal>
      )}
      {children(childProps)}
    </>
  );
}

BatchActions.propTypes = {
  children: PropTypes.func,
  contents: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(DefaultFolderRow.propTypes),
      PropTypes.shape(DefaultFileRow.propTypes),
    ]),
  ),
  currentFolder: PropTypes.shape({
    id: PropTypes.string,
    namespace: PropTypes.string,
    parentFolderId: PropTypes.string,
  }),
  onRemoveItems: PropTypes.func,
  onMoveItems: PropTypes.func,
};

const fileServerV2Discovery = async () => {
  const response = await fetch("/api/storage/v2/discover", {
    credentials: "include",
  });
  return response.ok;
};

// returns true if user can destroy all selected folders and files
const canDestroyAndMove = (selectedItemAndFolderIds) => {
  const folders = get(selectedItemAndFolderIds, ["folder"], []);
  const files = get(selectedItemAndFolderIds, ["file"], []);

  return every(folders, "canDestroy") && every(files, "canDestroy");
};

const isFinalizedOrLocked = (selectedItemAndFolderIds) => {
  const files = get(selectedItemAndFolderIds, ["file"], []);

  return some(files, "finalized") || some(files, "locked");
};

const Toolbar = ({
  selectedItemIds,
  removeSelected,
  selectedItemAndFolderIds,
  currentFolder,
  moveSelected,
}) => {
  const formRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const disabled =
    isEmpty(selectedItemIds) || !canDestroyAndMove(selectedItemAndFolderIds);

  const canBeMoved = currentFolder.namespace !== "activitystream_folders";

  const { data: fileServerV2available } = useQuery(
    "fileServerV2Discovery",
    fileServerV2Discovery,
    { staleTime: 60 * 60 * 1000 },
  );

  const serializeItemAndFolderIds = () => {
    const files = get(selectedItemAndFolderIds, ["file"], []);
    const folders = get(selectedItemAndFolderIds, ["folder"], []);

    return {
      file_ids: map(files, "id"),
      folder_ids: map(folders, "id"),
      current_folder_id: currentFolder.id,
    };
  };

  const startDownload = async () => {
    setIsDownloading(true);

    const response = await fetch("/api/v1/file_token", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(serializeItemAndFolderIds()),
    });

    const responseData = await response.json();

    formRef.current.token.value = responseData.token;
    formRef.current.submit();
    setIsDownloading(false);
  };

  return (
    <>
      <div className="fixed bottom-10 w-full lg:w-[calc(100%-260px)] 2xl:w-[calc(1536px - 280px)] flex justify-center z-50">
        <div className="files-toolbar flex bg-gray-200 gap-2 p-2 px-6 rounded-full items-center">
          <span className="text-sm text-muted">
            {selectedItemIds.length === 1
              ? I18n.t("js.files.one_selected")
              : I18n.t("js.files.selected", {
                  count: selectedItemIds.length,
                })}
          </span>
          {fileServerV2available ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                startDownload(selectedItemIds);
              }}
              className="btn btn-light btn-sm"
              disabled={isDownloading}
            >
              <i className="fa-regular fa-cloud-download" />{" "}
              {I18n.t("js.files.show.toolbar.download_file")}
            </button>
          ) : null}

          {canBeMoved ? (
            <span
              title={
                isFinalizedOrLocked(selectedItemAndFolderIds)
                  ? I18n.t("js.files.batch_actions.disabled_hint")
                  : undefined
              }
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                className="btn btn-light btn-sm"
                disabled={disabled}
              >
                <i className="fa-regular fa-arrows" />{" "}
                {I18n.t("js.files.batch_actions.move")}
              </button>
            </span>
          ) : null}
          <span
            title={
              isFinalizedOrLocked(selectedItemAndFolderIds)
                ? I18n.t("js.files.batch_actions.disabled_hint")
                : undefined
            }
          >
            <button
              className="btn btn-danger btn-sm delete-button"
              disabled={disabled}
              onClick={preventDefault(removeSelected)}
            >
              <i className="fa-regular fa-trash-can" />{" "}
              {I18n.t("js.files.batch_actions.delete")}
            </button>
          </span>
        </div>
        {showModal ? (
          <FolderSelectModal
            modalTitle={I18n.t("js.files.move.modal.title")}
            currentRootFolder={currentFolder}
            selectedItemIds={selectedItemIds}
            onSelectFolder={moveSelected}
            closeModal={() => setShowModal(false)}
          />
        ) : null}
        <form ref={formRef} method="post" action="/api/storage/v2/archive">
          <input type="hidden" name="token" />
        </form>
      </div>
    </>
  );
};
Toolbar.propTypes = {
  removeSelected: PropTypes.func,
  selectedItemIds: PropTypes.array,
  moveSelected: PropTypes.func,
  currentFolder: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default BatchActions;
