import { useMutation, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { LayoutRow, LayoutType } from "components/channels/types";

type LayoutRowParams = {
  id: string;
  type: LayoutType;
  widget_ids: (string | null)[];
};

type LayoutResponse = {
  rows: LayoutRow[];
};

export function useChannelLayout(channelSlug: string | undefined) {
  return useQuery<LayoutResponse>(`/channels/${channelSlug}/layout`, {
    enabled: !!channelSlug,
  });
}

export function useUpdateChannelLayout(channelSlug: string) {
  return useMutation<LayoutResponse, Error, LayoutRowParams[]>((newItems) =>
    fetchApi(`/channels/${channelSlug}/layout`, {
      method: "PUT",
      body: { rows: newItems },
    }),
  );
}
