import React, { useEffect, useRef, useState, useCallback } from "react";
import { navigate, Redirect, useParams } from "helpers/tixxt-router";
import ChannelPage from "components/channels/ChannelPage";
import { useWidget } from "components/channels/api";
import "./widgets.css";
import PageLoading from "components/shared/PageLoading";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { findIndex, includes } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/pro-solid-svg-icons";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

export default function ShowWidgetPage() {
  const { channelSlug, widgetId } = useParams();
  const { data, isLoading } = useWidget({ channelSlug, widgetId });

  if (isLoading) {
    return <PageLoading />;
  }

  switch (data?.type) {
    case "images": {
      const images = data.images;
      return (
        <>
          <ChannelPage />
          {images && <ImagesGalleryPage {...data} images={images} />}
        </>
      );
    }
    default:
      // redirect to channel page for all widgets other than images, which should show gallery in overlay
      return window.Settings?.development ? (
        <div className={"alert alert-info prose"}>
          TODO Implement case for <code>{data?.type}</code> in ShowWidgetPage /
          WidgetPageByType
        </div>
      ) : (
        <Redirect to={`/channels/${channelSlug}`} />
      );
  }
}

type ImagesGalleryPageProps = {
  images: { id: string }[];
  title?: string;
  description?: string;
};

function ImagesGalleryPage({
  title,
  description,
  images,
}: ImagesGalleryPageProps) {
  const { channelSlug } = useParams();
  const [lightbox, setLightbox] = useState<{
    id: string | null;
    open: boolean;
    loading: boolean;
  }>({ id: null, open: false, loading: false });
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    dialogRef.current?.showModal();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const showNextImage = useCallback(
    (e: React.MouseEvent | KeyboardEvent) => {
      e.preventDefault();
      const currentIndex = findIndex(images, { id: lightbox.id || "" });

      if (currentIndex !== -1 && currentIndex + 1 < images.length) {
        const nextImage = images[currentIndex + 1];
        if (nextImage) {
          setLightbox({
            id: nextImage.id,
            open: true,
            loading: lightbox.id != nextImage.id,
          });
        }
      }
    },
    [images, lightbox.id],
  );

  const showPrevImage = useCallback(
    (e: React.MouseEvent | KeyboardEvent) => {
      e.preventDefault();
      const currentIndex = findIndex(images, { id: lightbox.id || "" });

      if (currentIndex > 0) {
        const prevImage = images[currentIndex - 1];
        if (prevImage) {
          setLightbox({
            id: prevImage.id,
            open: true,
            loading: lightbox.id != prevImage.id,
          });
        }
      }
    },
    [images, lightbox.id],
  );

  function handleKeyDown(event: KeyboardEvent) {
    const nextKeys = ["ArrowRight", "ArrowUp"];

    if (
      includes([...nextKeys, "ArrowLeft", "ArrowDown", "Escape"], event.key)
    ) {
      event.preventDefault();

      if (event.key === "Escape" && lightbox.open) {
        setLightbox((prevLightbox) => ({
          ...prevLightbox,
          open: false,
        }));
      } else {
        includes(nextKeys, event.key)
          ? showNextImage(event)
          : showPrevImage(event);
      }
    }
  }

  useEffect(() => {
    if (lightbox.open) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [lightbox.open, showNextImage, showPrevImage]);

  return createPortal(
    <dialog
      ref={dialogRef}
      className={
        "tixxt__dialog rounded w-full overflow-y-scroll bg-transparent"
      }
      onClick={(e) => {
        if (e.target == e.currentTarget)
          navigate(`/channels/${channelSlug}`, { replace: true });
      }}
      onClose={(e) => {
        if (e.target == e.currentTarget)
          navigate(`/channels/${channelSlug}`, { replace: true });
      }}
    >
      <div className="my-4 flex flex-col gap-4">
        <h1 className="text-2xl font-semibold text-center">{title}</h1>
        <div className="px-20 text-center prose">{description}</div>
      </div>
      <div
        className={
          "grid gap-1 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6"
        }
      >
        {images.map(({ id }) => (
          <div key={id} className={"aspect-square overflow-hidden"}>
            <a
              href={`#img-${id}`}
              onClick={(e) => {
                e.preventDefault();
                setLightbox({ id, open: true, loading: lightbox.id != id });
              }}
            >
              <img
                alt=""
                aria-hidden
                src={`/api/storage/images/${id}/get/435x435`}
                className={
                  "w-full h-full object-cover object-center transition hover:scale-110"
                }
              />
            </a>
          </div>
        ))}
        {lightbox && (
          <div
            className={classNames("images-gallery__lightbox", {
              "opacity-0 pointer-events-none": !lightbox.open,
            })}
            onClick={(e) => {
              if (e.target == e.currentTarget)
                setLightbox({ ...lightbox, open: false });
            }}
          >
            {lightbox.id && (
              <div className="flex gap-4">
                {findIndex(images, { id: lightbox.id }) > 0 && (
                  <button onClick={showPrevImage}>
                    <FontAwesomeIcon
                      className="text-primary opacity-75 h-16 w-16"
                      icon={faChevronCircleLeft}
                    />
                  </button>
                )}
                <img
                  key={lightbox.id}
                  alt=""
                  aria-hidden
                  src={`/api/storage/images/${lightbox.id}/get/1920x1200`}
                  className={classNames(
                    "w-full h-full object-contain transition-opacity duration-700",
                    { "opacity-0": lightbox.loading },
                  )}
                  onLoad={() => setLightbox({ ...lightbox, loading: false })}
                />
                {findIndex(images, { id: lightbox.id }) < images.length - 1 && (
                  <button onClick={showNextImage}>
                    <FontAwesomeIcon
                      className="text-primary opacity-75 h-16 w-16"
                      icon={faChevronCircleRight}
                    />
                  </button>
                )}
              </div>
            )}
            <div className={classNames({ hidden: !lightbox.loading })}>
              <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
            </div>
          </div>
        )}
      </div>
    </dialog>,
    document.body,
  );
}
