import React from "react";
import { networkMembershipRoles } from "components/shared/PermissionPicker";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import DirectoryPickerInput from "components/shared/form/fields/DirectoryPickerInput";
import Checkbox from "components/shared/form/fields/Checkbox";
import FormActions from "components/shared/form/fields/FormActions";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { forEach, map } from "lodash";
import { useNavigate } from "helpers/tixxt-router";

type AssignChannelSubscriptionsArgs = {
  members: { id: string; type: string }[] | null;
  channels: string[];
  subscribe_all_network_members: boolean;
};

type FormData = {
  members: { id: string; type: string }[];
  channels: { slug: string }[];
  subscribe_all_network_members: boolean;
};

export default function AssignChannelSubscriptionsForm() {
  const navigate = useNavigate();
  const methods = useForm<FormData>();
  const { handleSubmit } = methods;

  const { mutate: assignChannelSubscriptions, isLoading } = useMutation<
    unknown,
    unknown,
    AssignChannelSubscriptionsArgs
  >(
    (body: AssignChannelSubscriptionsArgs) =>
      fetchApi(
        "/administration/channel_settings/assign_channel_subscriptions",
        { method: "POST", body },
      ),
    {
      onSuccess: () => {
        toastr.success(
          I18n.t(
            "js.administration.channel_settings.assign_channel_subscriptions.assign_success",
          ),
        );
      },
      onError: () => {
        toastr.error(I18n.t("js.administration.widgetbars.load_error"));
      },
    },
  );

  const subscribeAllNetworkMembers = useWatch({
    control: methods.control,
    name: "subscribe_all_network_members",
  });

  function submit({
    channels,
    members,
    subscribe_all_network_members,
  }: FormData) {
    const channelSlugs: string[] = map(channels, "slug");
    const memberData = subscribe_all_network_members
      ? null
      : map(members, (member) => ({
          id: member.id,
          type: member.type,
        }));
    assignChannelSubscriptions({
      members: memberData,
      channels: channelSlugs,
      subscribe_all_network_members,
    });
  }

  function onValidationError(errors) {
    forEach(document.getElementsByClassName("field-error"), (element) => {
      element?.classList.remove("field-error");
    });

    forEach(errors, (error) => {
      toastr.error(error?.message || I18n.t("generic_error"));
    });
  }

  return (
    <FormProvider {...methods}>
      <form
        className="form-horizontal mb-6"
        onSubmit={handleSubmit(submit, onValidationError)}
      >
        <DirectoryPickerInput
          label={I18n.t(
            "js.administration.channel_settings.assign_channel_subscriptions.members.label",
          )}
          name="members"
          multi
          extraOptions={networkMembershipRoles}
          disabled={subscribeAllNetworkMembers}
        >
          <Checkbox
            name="subscribe_all_network_members"
            label={I18n.t(
              "js.administration.channel_settings.assign_channel_subscriptions.all_network_members.label",
            )}
            className="mt-2"
          />
        </DirectoryPickerInput>
        <DirectoryPickerInput
          label={I18n.t(
            "js.administration.channel_settings.assign_channel_subscriptions.channels.label",
          )}
          name="channels"
          endpoint="/directory/channels"
          query={{ only_public: true }}
          disabled={false}
          showDefaultOptions={false}
          required
          multi
        />
        <FormActions
          saveDisabled={isLoading}
          onCancel={(e) => {
            e.preventDefault();
            navigate("/administration");
          }}
        />
      </form>
    </FormProvider>
  );
}
