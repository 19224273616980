import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useTooltip from "../../hooks/useTooltip";

export default function DirectoryActionButton({
  disabled,
  icon,
  text,
  onClick,
  type,
  title,
}: {
  icon: { icon: IconProp; className?: string };
  text: string;
  type?: "primary" | "danger";
  onClick?: () => void;
  disabled?: boolean;
  title?: string;
}) {
  const tooltipRef = useTooltip<HTMLButtonElement>();
  return (
    <button
      ref={tooltipRef}
      className={classNames("btn btn-sm", {
        "btn-light": !type,
        "btn-primary": type === "primary",
        "btn-danger": type === "danger",
        disabled: disabled,
      })}
      title={title}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
    >
      <FontAwesomeIcon
        className={classNames("mr-1", icon.className)}
        icon={icon.icon}
      />
      {text}
    </button>
  );
}
