import React, { useState } from "react";
import { EmbedLink } from "components/channels/widgets/widgetsFields/linkWidget/types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-solid-svg-icons";

type EmbedLinkViewArgs = {
  embed: EmbedLink;
  image_url: string | null;
  secureEmbedServiceUrl?: string;
};

export default function EmbedLinkView({
  embed,
  image_url,
  secureEmbedServiceUrl,
}: EmbedLinkViewArgs) {
  const [showEmbed, setShowEmbed] = useState(false);

  return showEmbed ? (
    secureEmbedServiceUrl ? (
      <iframe
        className="border-none h-full w-full"
        src={secureEmbedServiceUrl}
        allow="fullscreen"
      />
    ) : (
      <div
        className="widget__link_embed h-full w-full"
        dangerouslySetInnerHTML={{ __html: embed.html }}
      />
    )
  ) : (
    <div
      className="flex h-full w-full items-center justify-center bg-cover bg-center"
      style={{
        gridTemplateAreas: "stack",
        backgroundImage: image_url ? `url("${image_url}")` : "none",
      }}
    >
      {!image_url && embed.provider_icon && (
        <i className={classNames("provider-icon fa-5x", embed.provider_icon)} />
      )}
      <div
        className="flex h-full w-full items-end"
        style={{ gridArea: "stack" }}
      >
        <span
          className="p-3 font-medium justify-center items-center flex w-full gap-2 group toggle-embed-icon text-center bg-white/80 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            setShowEmbed(true);
          }}
        >
          <FontAwesomeIcon
            className="inline group-hover:hidden"
            icon={faToggleOff}
            size="2xl"
          />
          <FontAwesomeIcon
            className="hidden group-hover:inline"
            icon={faToggleOn}
            size="2xl"
          />
          {I18n.t("js.activitystreams.embed.show")}
        </span>
      </div>
    </div>
  );
}
