import { fetchApi } from "helpers/reactQueryApi";
import { stringify } from "query-string";

export type MemberEntry = {
  id: string;
  type: "member";
  name: string;
  path?: string;
  images?: {
    small: string;
    medium: string;
    large: string;
    "40x40@2": string;
    "64x64@2": string;
  };
  profile?: { [key: string]: any };
  detection_profile_field?: null | string;
};

export type ChannelEntry = {
  id: string;
  slug: string;
  type: "channel";
  title: string;
  status: string;
  path?: string;
  image: {
    _id: string;
  };
};

export type GroupEntry = {
  id: string;
  type: "group";
  name: string;
  url?: string;
  profile_image?: string | null;
  category: {
    id: string;
    name: string;
    plural_name: string;
  };
  membership_roles: {
    id: string;
    slug?: string;
    name: string;
    plural_name: string;
    color?: string;
    type?: "membershipRole";
  }[];
};

export type GroupAdminEntry = {
  id: string;
  type: "group_admin";
  name: string;
  url?: string;
  profile_image?: string | null;
  category: {
    id: string;
    name: string;
    plural_name: string;
  };
  membership_roles: {
    id: string;
    slug?: string;
    name: string;
    plural_name: string;
    color?: string;
    type?: "membershipRole";
  }[];
};

export type DirectoryEntry = MemberEntry | GroupEntry | GroupAdminEntry;

export async function fetchDirectory(
  query: {
    q?: string;
    include_membership_roles?: boolean;
  },
  endpoint?: string,
): Promise<{ entries: DirectoryEntry[] }> {
  return await fetchApi(`${endpoint || "/directory"}?${stringify(query)}`);
}
