import React, { useState } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  useWatch,
} from "react-hook-form";
import Label from "components/shared/form/Label";
import ReactSelect from "react-select";
import { isEmpty, map } from "lodash";
import moment from "moment";
import classNames from "classnames";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import DateTimePicker from "components/shared/form/fields/DateTimePicker";
import PartialToggle from "components/appointments/form/PartialToggle";

const FREQUENCY_LOOKUP = {
  weekly: "week",
  monthly: "month",
  yearly: "year",
  daily: "day",
  custom: "custom",
};

type RecurringSelectArgs = {
  name: string;
  label?: string;
  control: Control<any>;
  required?: boolean;
  startDate: string;
};

export default function RecurringSelectField({
  name,
  label,
  control,
  required,
  startDate,
}: RecurringSelectArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={required} />}
      <div className="controls">
        <Controller
          name={name}
          control={control}
          render={({ field }) => RecurringSelect({ field, startDate, control })}
        />
      </div>
    </div>
  );
}

function RecurringSelect({
  field,
  startDate,
  control,
}: {
  field: ControllerRenderProps;
  startDate: string;
  control: Control<any>;
}) {
  const { recurrence_frequency, recurrence_end_time, recurrence_interval } =
    useWatch({
      control,
      name: "recurrence_pattern",
    });
  const [showEndPicker, setShowEndPicker] = useState(
    !isEmpty(recurrence_end_time),
  );

  // eslint-disable-next-line prefer-const
  let [isCustom, setIsCustom] = useState(recurrence_frequency === "custom");
  isCustom = isCustom || (recurrence_interval && recurrence_interval !== 1);

  const level1Options = map(
    ["never", "daily", "weekly", "monthly", "yearly", "custom"],
    (pattern) => ({
      value: pattern,
      label: I18n.t(
        `js.calendars.appointment.recurrence-pattern.frequency.${pattern}`,
      ),
    }),
  );

  const customOptions = map(
    ["daily", "weekly", "monthly", "yearly"],
    (pattern) => ({
      value: pattern,
      label: I18n.t(
        `js.calendars.appointment.recurrence-pattern.custom_frequency.${pattern}`,
      ),
    }),
  );

  function level1HandleChange(value: {
    [key: string]: string | number | null;
  }) {
    const isCustomFrequency = value.recurrence_frequency === "custom";
    const isFieldFrequencyNever = field.value.recurrence_frequency === "never";

    setIsCustom(isCustomFrequency);

    if (!isCustomFrequency) {
      handleChange(value);
    }

    if (isCustomFrequency && isFieldFrequencyNever) {
      field.onChange({
        ...field.value,
        recurrence_frequency: "daily",
      });
    }
  }

  function handleChange(value: { [key: string]: string | number | null }) {
    if (value.recurrence_frequency) {
      if (value.recurrence_frequency !== recurrence_frequency) {
        field.onChange({
          ...field.value,
          recurrence_end_time: null,
          recurrence_interval:
            value.recurrence_frequency !== "custom" ? 1 : recurrence_interval, // Reset recurrence_interval if not custom
          ...value,
        });
        setShowEndPicker(false);
        return;
      }
    }

    field.onChange({
      ...field.value,
      ...value,
    });
  }

  return (
    <div className="border-box flex flex-col p-3 gap-y-3">
      <div className="control-group">
        <label className="control-label">
          {I18n.t("js.calendars.appointment.recurrence_pattern.interval.label")}
        </label>
        <div className="controls remove-input-txt-border">
          <ReactSelect
            closeMenuOnSelect
            noOptionsMessage={() => I18n.t("js.plugins.select2.no_match")}
            value={{
              value: isCustom ? "custom" : field.value.recurrence_frequency,
              label: I18n.t(
                `js.calendars.appointment.recurrence-pattern.frequency.${
                  isCustom ? "custom" : recurrence_frequency
                }`,
              ),
            }}
            options={level1Options}
            onChange={(selectedOption) =>
              level1HandleChange({
                recurrence_frequency: selectedOption?.value,
              })
            }
            className="form-select-container"
            classNamePrefix="form-select"
            isSearchable={false}
            unstyled
          />
        </div>
      </div>
      {isCustom && (
        <div className="control-group">
          <label className="control-label">
            {I18n.t("js.calendars.appointment.recurrence-pattern.custom.label")}
          </label>
          <div className="controls flex items-center gap-x-2">
            <input
              type="number"
              className="form-control"
              value={field.value.recurrence_interval || 1}
              onChange={(e) =>
                handleChange({ recurrence_interval: parseInt(e.target.value) })
              }
              min={1}
            />
            <ReactSelect
              value={{
                value: field.value.recurrence_frequency,
                label: I18n.t(
                  `js.calendars.appointment.recurrence-pattern.custom_frequency.${recurrence_frequency}`,
                ),
              }}
              options={customOptions}
              onChange={(selectedOption) =>
                handleChange({ recurrence_frequency: selectedOption?.value })
              }
              className="form-select-container w-full"
              classNamePrefix="form-select"
              isSearchable={false}
              unstyled
            />
          </div>
        </div>
      )}
      {recurrence_frequency !== "never" && (
        <PartialToggle
          onToggle={(showPartial) => {
            handleChange({
              recurrence_end_time: showPartial
                ? null
                : field.value.recurrence_end_time ||
                  moment(startDate)
                    .add(
                      recurrence_interval,
                      FREQUENCY_LOOKUP[recurrence_frequency],
                    )
                    .format(),
            });
          }}
          label={(showPartial) =>
            showPartial
              ? I18n.t("js.calendars.date_range.remove_end")
              : I18n.t("js.calendars.date_range.set_end.link")
          }
          iconClassName={(showPartial) =>
            classNames({ "text-danger": showPartial })
          }
          hideIcon={faXmark}
          shouldShowPartial={showEndPicker}
        >
          <DateTimePicker
            name="recurrence_pattern.recurrence_end_time"
            label={I18n.t("js.calendars.date_range.end_date.label")}
            interval={moment.duration(
              recurrence_interval,
              FREQUENCY_LOOKUP[recurrence_frequency],
            )}
          />
        </PartialToggle>
      )}
    </div>
  );
}
