import React from "react";
import { useSelector, useStore } from "react-redux";
import { SubmissionError } from "redux-form";

import persistItem from "helpers/items/persistItem";
import { updateProfileStep } from "actions/onboarding";
import { selectNetworkId, currentMemberSelector } from "selectors/environment";
import MembershipForm from "components/memberships/MembershipForm";
import { useLoadApp } from "../appCreator/api";

export default function ProfileOnboarding() {
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const membershipId = useSelector(currentMemberSelector).id;
  const externalItemId = membershipId;
  const store = useStore();
  const { data: app } = useLoadApp({
    externalAppId,
  });

  const onSubmit = async (values, dispatch) => {
    await persistItem(app, store.dispatch, {
      externalAppId,
      externalItemId,
      values,
    });

    const onboardingResult = await dispatch(updateProfileStep({}));
    if (onboardingResult.error) throw new SubmissionError();

    window.location.href = onboardingResult.redirect_to || "/";
    toastr.success(I18n.t("js.saving_successful"));
  };

  return (
    <MembershipForm
      externalAppId={externalAppId}
      externalItemId={externalItemId}
      onSubmit={onSubmit}
      title={I18n.t("js.apps.profile.edit.title")}
      allowSaveWithoutChange
    />
  );
}
