import React, { useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { map, get, find, compact, isEqual } from "lodash";
import moment from "moment";
import classNames from "classnames";

import "../appointments.module.css";
import AppointmentLine from "./AppointmentLine";
import useHover from "../../shared/hooks/useHover";

function CalendarSheetCell({
  day,
  appointments,
  isFirst,
  weekNum,
  notActualMonth,
  calendars,
  className,
  maxHeight,
  columnNumber,
  lastRow,
  hideActions,
}) {
  const momentDay = moment(day, "YYYY-MM-DD");
  const divRef = useRef(null);
  const appointmentHeight = 21; // h-5 + gap-px = 21px
  const headerHeight = 24; // h-5 + gap-1 = 24px
  let visibleAppointmentCount = Math.max(
    4, // minimum appointment count
    Math.floor((maxHeight - headerHeight - 8) / appointmentHeight),
  );
  const height = visibleAppointmentCount * appointmentHeight + headerHeight;
  let hiddenAppointmentCount = 0;
  if (appointments.length > visibleAppointmentCount) {
    visibleAppointmentCount -= 1; // "x more" covers one line
    hiddenAppointmentCount = appointments.length - visibleAppointmentCount;
  }

  const hovered = useHover(divRef);

  const [initialDimensions, setInitialDimensions] = useState();
  useLayoutEffect(() => {
    if (!divRef.current) return;
    const newInitialDimensions = {
      width: divRef.current.clientWidth - 8, // subtract padding
      height: divRef.current.clientHeight - 8, // subtract padding
    };
    if (isEqual(initialDimensions, newInitialDimensions)) return;

    setInitialDimensions(newInitialDimensions);
  }, [divRef.current, maxHeight, window.innerWidth]);

  const style = divRef.current
    ? appointments.length > 0 && hovered && initialDimensions
      ? {
          width: initialDimensions.width * 2.25, // 2.25 width expansion on hover, neighboring cells are partly visible
          height: Math.max(
            initialDimensions.height,
            appointments.length * appointmentHeight + headerHeight,
          ),
        }
      : initialDimensions
    : null;

  const bg = momentDay.isSame(moment(), "day")
    ? "bg-today"
    : notActualMonth
      ? "bg-gray-50"
      : "bg-white";

  return (
    <div
      ref={divRef}
      className={classNames(
        "CalendarSheetCell group relative flex flex-col p-1 border-neutral box-content overflow-hidden hover:overflow-visible",
        bg,
        className,
      )}
      style={{ height }}
      onDoubleClick={() => {
        if (hideActions) return;
        Backbone.history.navigate(`/appointments/new?date=${day}`, {
          trigger: true,
        });
      }}
    >
      {style ? (
        <>
          <div
            className={classNames(
              "absolute top-0 flex flex-col gap-1 p-1 overflow-hidden box-content",
              "group-hover:z-10 group-hover:shadow-lg group-hover:border group-hover:border-t-0 group-hover:border-neutral group-hover:rounded",
              // "group-hover:p-2 group-hover:-translate-y-[5px]",
              columnNumber === 0
                ? "left-0 group-hover:-left-px"
                : columnNumber === 6
                  ? "right-0 group-hover:-right-px"
                  : "left-0 group-hover:left-1/2 group-hover:-translate-x-1/2",
              lastRow ? "group-hover:-bottom-px group-hover:top-auto" : null,
              divRef.current ? "group-hover:transition-dimensions" : null,
              hovered ? bg : null,
              notActualMonth && !hovered ? "opacity-50" : null,
            )}
            style={style}
          >
            <div className="flex shrink-0 justify-between items-center relative truncate flex-nowrap h-5 gap-1">
              <span className={"text-sm font-medium"}>
                {isFirst || momentDay.format("DD") === "01"
                  ? ` ${momentDay.format("DD. MMM")}`
                  : momentDay.format("DD")}{" "}
              </span>
              {!hideActions && (
                <div className="hidden group-hover:inline grow text-muted truncate cursor-default text-sm">
                  {I18n.t(
                    "js.calendars.overview.sheet_cell_line.double_click_to_add",
                  )}
                </div>
              )}
              {weekNum ? (
                <span className="badge bg-gray-400 text-white text-[10px] px-1">
                  {weekNum}
                </span>
              ) : null}
            </div>
            <div className="appointmentsList shrink-0 flex flex-col gap-px">
              {map(appointments, (appointment, index) => {
                let markers = map(get(appointment, "calendar_ids"), (calId) =>
                  get(
                    find(
                      calendars,
                      (calendar) => get(calendar, "id") === calId,
                    ),
                    "color",
                  ),
                );
                if (appointment.unknown_calendars) {
                  markers = markers.concat(
                    map(appointment.unknown_calendars, "color"),
                  );
                }

                return (
                  <AppointmentLine
                    key={index}
                    {...appointment}
                    day={momentDay}
                    markers={compact(markers)}
                    className={
                      index >= visibleAppointmentCount
                        ? "hidden group-hover:flex"
                        : ""
                    }
                  />
                );
              })}
            </div>
          </div>
          {hiddenAppointmentCount > 0 ? (
            <span className="group-hover:hidden absolute inset-x-0 bottom-0 text-sm text-muted px-2 pb-1">
              {hiddenAppointmentCount}{" "}
              {I18n.t("js.calendars.overview.sheet_cell_line.more")}
            </span>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

CalendarSheetCell.propTypes = {
  day: PropTypes.string,
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      date: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
        all_day: PropTypes.bool,
        time_zone: PropTypes.string,
      }),
      calendar_ids: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  isFirst: PropTypes.bool,
  weekNum: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  notActualMonth: PropTypes.bool,
  calendars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
};

export default CalendarSheetCell;
