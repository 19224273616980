import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import FileIcon from "../../shared/FileIcon";
import FileInfo from "./FileInfo";

const FileRow = React.memo(function FileRow({
  id,
  name,
  extension,
  handleNavigate,
  version,
  size,
  author,
  updated_at,
  finalizedAt,
  lockedUntil,
  lockedById,
  extraColumns,
  profile = {},
  className,
  content_type,
}) {
  return (
    <tr className={classNames("file-row", className)}>
      <td className="col-icon file-icon text-center" width="1%">
        <FileIcon extension={extension} className={"text-xl"} />
      </td>
      <FileInfo
        id={id}
        name={name}
        handleNavigate={handleNavigate}
        version={version}
        size={size}
        author={author}
        updatedAt={updated_at}
        finalizedAt={finalizedAt}
        lockedUntil={lockedUntil}
        lockedById={lockedById}
        extraColumns={extraColumns}
        profile={profile}
        contentType={content_type}
      />
    </tr>
  );
});

FileRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  handleNavigate: PropTypes.func,
  version: PropTypes.shape({ major: PropTypes.number }),
  size: PropTypes.number,
  author: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }),
  updated_at: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  finalizedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  lockedUntil: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  lockedById: PropTypes.string,
  extraColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.func.isRequired,
      Data: PropTypes.func.isRequired,
    }),
  ),
  profile: PropTypes.object,
  className: PropTypes.string,
  striped: PropTypes.bool,
  content_type: PropTypes.string,
};

export default FileRow;
export const FileRowPropTypes = FileRow.propTypes;
