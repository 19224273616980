import classnames from "classnames";
import { get, isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import { getImageUrl } from "../../../../selectors/chat/application";
import { getMessageDeliveryStatus } from "../../../../selectors/chat/messages";
import { getMyId, getMyProfile } from "../../../../selectors/chat/profile";
import MessageAuthor from "./MessageAuthor";
import MessageSettings from "./MessageSettings";
import MessageText from "./MessageText";
import QuotedMessage from "./QuotedMessage";
import MessageDeliveryStatus from "./MessageDeliveryStatus";

const MessageDate = ({ date, format, className }) => {
  const fullDate = moment(date).format("DD.MM.YYYY HH:mm");

  return (
    <time
      className={classnames("chatMessageDate", className)}
      dateTime={fullDate}
      title={fullDate}
    >
      {moment(date).format(format)}
    </time>
  );
};

MessageDate.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
  className: PropTypes.string,
};

const ChatMessage = ({
  id,
  chat_id,
  text,
  from,
  created_at,
  myMessage,
  groupedMessage,
  quoted_message,
  deliveryStatus,
}) => (
  <div
    className={classnames("chat__message group hover:relative focus:relative", {
      "chat__message--my": myMessage,
      "chat__message--grouped": groupedMessage,
      "chat__message--unread": !myMessage && deliveryStatus === "received",
    })}
  >
    <div className="chat__bubble_arrow" />
    <div className="chat__message_header flex gap-2 mb-1 items-center">
      <MessageAuthor {...from} />
    </div>
    {!isEmpty(quoted_message) ? (
      <QuotedMessage message={quoted_message} />
    ) : null}
    <MessageText text={text} />
    <div className="chat__message_meta text-xs text-muted flex gap-1 items-center">
      <MessageDate date={created_at} format="HH:mm" className="time" />
      {myMessage ? (
        <MessageDeliveryStatus deliveryStatus={deliveryStatus} />
      ) : null}
    </div>
    <MessageSettings
      id={id}
      chatId={chat_id}
      fromId={from.id}
      fromDeleted={from.deleted}
      myMessage={myMessage}
      className={
        "hidden group-hover:block group-focus:block absolute right-0 top-0 drop-shadow"
      }
    />
  </div>
);
ChatMessage.propTypes = {
  id: PropTypes.string,
  chat_id: PropTypes.string,
  text: PropTypes.string,
  from: PropTypes.shape(MessageAuthor.propTypes),
  quoted_message: PropTypes.object,
  created_at: PropTypes.string,
  myMessage: PropTypes.bool,
  groupedMessage: PropTypes.bool,
  qoutedMessageId: PropTypes.string,
  settings: PropTypes.bool,
  deliveryStatus: PropTypes.oneOf(["pushed", "persisted", "received", "read"]),
};

export default compose(
  connect((state, { id, chat_id: chatId, from }) => {
    const myMessage = from.id === getMyId(state);
    // From can be undefined in some cases
    const fromProfile = myMessage ? getMyProfile(state) : from;

    return {
      myMessage,
      deliveryStatus: getMessageDeliveryStatus(state, { id, chatId }),
      from: {
        ...fromProfile,
        imageUrl: getImageUrl(state, get(fromProfile, "image_id"), "80x80"),
      },
    };
  }),
)(ChatMessage);
