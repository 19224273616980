// Änderungen an app/javascript/controllers/shared/toggle_controller.js
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--toggle"
// Can be set on an element containing radio buttons with value 0 and 1 (target will be shown when 1 is checked)
//   @see app/views/administration/appointment_settings/_form.html.haml
// or on a single checkbox (target will be shown when checkbox is checked)
//   @see app/views/administration/newsletter_settings/_form.html.haml
// or on a button to toggle visibility of target elements
//   @see app/views/administration/invites/sent.html.haml
export default class extends Controller {
  static targets = ["toggleable"];

  connect() {
    this.target = this.element.dataset.toggleTarget;

    if (
      !(
        this.element.nodeName === "BUTTON" ||
        this.element.classList.contains("toggle-filter")
      )
    ) {
      this.toggle();
    }
  }

  toggle(event) {
    if (
      event &&
      (this.element.nodeName === "BUTTON" ||
        this.element.classList.contains("toggle-filter"))
    ) {
      event.preventDefault();
    }

    let show;
    if (this.element.nodeName === "INPUT") {
      show = this.element.checked;
    } else if (
      this.element.nodeName === "BUTTON" ||
      this.element.classList.contains("toggle-filter")
    ) {
      const targetElements = document.querySelectorAll(this.target);
      show =
        targetElements.length > 0
          ? targetElements[0].classList.contains("hidden")
          : false;

      this.element.classList.toggle("active", show);
    } else {
      show =
        this.element.querySelector(
          '[data-action="shared--toggle#toggle"]:checked',
        )?.value === "1";
    }

    document
      .querySelectorAll(this.target)
      .forEach((elem) => elem.classList.toggle("hidden", !show));
  }
}
