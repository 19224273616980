import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faRegularStar } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faSolidStar } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import useTooltip from "../../../hooks/useTooltip";

type ToggleFavoriteButtonArgs = {
  title: string;
  toggleFavorited: () => void;
  isLoading: boolean;
  favorited: boolean;
};

export default function ToggleFavoriteButton({
  title,
  isLoading,
  toggleFavorited,
  favorited,
}: ToggleFavoriteButtonArgs) {
  const tooltipRef = useTooltip<HTMLButtonElement>(title);

  return (
    <button
      ref={tooltipRef}
      data-bs-toggle="tooltip"
      title={title}
      className={classNames("btn btn-sm", {
        "cursor-wait": isLoading,
        "text-favorite hover:text-favorite": favorited,
        "text-gray-200 hover:text-favorite/50": !favorited,
      })}
      disabled={isLoading}
      onClick={toggleFavorited}
    >
      <FontAwesomeIcon icon={favorited ? faSolidStar : faRegularStar} />
    </button>
  );
}
