import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

const useChannelSubscriptionToggle = ({
  onSuccessFunction,
  channelSlug,
}: {
  onSuccessFunction: (subscribed: boolean) => void;
  channelSlug: string;
}) => {
  const { mutate: subscribeChannel } = useMutation(
    () => fetchApi(`/channels/${channelSlug}/subscribe`, { method: "POST" }),

    {
      onSuccess: () => {
        onSuccessFunction(true);
      },
      onError: (error: any) =>
        toastr.error(error.message || I18n.t("js.generic_error")),
    },
  );

  const { mutate: unsubscribeChannel } = useMutation(
    () => fetchApi(`/channels/${channelSlug}/unsubscribe`, { method: "POST" }),

    {
      onSuccess: () => {
        onSuccessFunction(false);
      },
      onError: (error: any) =>
        toastr.error(error.message || I18n.t("js.generic_error")),
    },
  );

  return {
    subscribeChannel,
    unsubscribeChannel,
  };
};

export default useChannelSubscriptionToggle;
