import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRepeat, faClock } from "@fortawesome/pro-regular-svg-icons";
import { isSameDay } from "date-fns";

import {
  AppointmentDate as AppointmentDateType,
  Appointment,
} from "../../../@types/appointments";
import formatDate from "helpers/formatDate";
import { TZDate } from "@date-fns/tz";

type AppointmentDateArgs = {
  date: AppointmentDateType;
  isRecurring?: boolean;
  recurrence_pattern?: Appointment["recurrence_pattern"];
  next_occurrence_url?: string;
  next_occurrence_date?: string;
};

export function useAppointmentDate(date: AppointmentDateType) {
  const appointmentStart = new Date(date.start);
  const appointmentEnd = date.end ? new Date(date.end) : null;
  const formattedStart = formatDate(
    appointmentStart,
    date.all_day ? "PPPP" : I18n.t("js.time.formats.date_fns.long_datetime"),
  );

  const formattedEnd = appointmentEnd
    ? isSameDay(appointmentStart, appointmentEnd)
      ? formatDate(appointmentEnd, "HH:mm")
      : formatDate(
          appointmentEnd,
          date.all_day
            ? "PPPP"
            : I18n.t("js.time.formats.date_fns.long_datetime"),
        )
    : null;

  return { appointmentStart, appointmentEnd, formattedStart, formattedEnd };
}

export default function AppointmentDate({
  date,
  isRecurring = false,
  recurrence_pattern,
  next_occurrence_url,
  next_occurrence_date,
}: AppointmentDateArgs) {
  const { appointmentStart, appointmentEnd, formattedStart, formattedEnd } =
    useAppointmentDate(date);

  function FormattedTimeZone() {
    return (
      <div>
        <span className="timezone text-muted">{`${date.time_zone
          ?.replace(/\//g, ", ")
          .replace(/_/g, " ")} (${formatDate(
          appointmentStart,
          "z",
        )} UTC${formatDate(appointmentStart, "xxxxx")})`}</span>
      </div>
    );
  }

  return (
    <>
      {isRecurring && (
        <div className="detail-date detail-block flex gap-2">
          <FontAwesomeIcon icon={faArrowsRepeat} className="detail-icon" />
          <div className="flex flex-col">
            <div>
              {I18n.t("js.calendars.recurring_appointment.starts_at") +
                " " +
                formatDate(appointmentStart, "PPPP")}
            </div>
            <div>
              {recurrence_pattern?.recurrence_end_time
                ? I18n.t("js.calendars.recurring_appointment.ends_at") +
                  " " +
                  formatDate(
                    new TZDate(
                      recurrence_pattern.recurrence_end_time,
                      date.time_zone,
                    ),
                    "PPPP",
                  )
                : I18n.t("js.calendars.recurring_appointment.no_end")}
            </div>
            <div>
              {I18n.t("js.calendars.recurring_appointment.frequency") +
                " " +
                (recurrence_pattern?.recurrence_interval === 1
                  ? I18n.t(
                      `js.calendars.appointment.recurrence-pattern.frequency.${recurrence_pattern.recurrence_frequency}`,
                    )
                  : I18n.t(
                      "js.calendars.appointment.recurrence-pattern.custom.frequency.label",
                      {
                        interval: recurrence_pattern?.recurrence_interval,
                        frequency: I18n.t(
                          `js.calendars.appointment.recurrence-pattern.custom_frequency.${recurrence_pattern?.recurrence_frequency}`,
                        ),
                      },
                    ))}
            </div>
          </div>
        </div>
      )}
      <div className="detail-date detail-block flex gap-2">
        <FontAwesomeIcon icon={faClock} className="detail-icon" />
        <div className="flex flex-col">
          {isRecurring &&
            I18n.t("js.calendars.recurring_appointment.first_occurrence")}
          {appointmentEnd && isSameDay(appointmentStart, appointmentEnd) ? (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: I18n.t(
                    "js.calendars.date_range.formatted_start_and_end_same_day_html",
                    {
                      start_date: formatDate(appointmentStart, "PPPP"),
                      start_time: formatDate(appointmentStart, "HH:mm"),
                      end: formatDate(appointmentEnd, "HH:mm"),
                    },
                  ),
                }}
              />
              {!date.all_day && <FormattedTimeZone />}
            </>
          ) : (
            <div>
              {`${I18n.t("js.calendars.date_range.start")}: `}
              <time className="font-semibold">{formattedStart}</time>
              {formattedEnd && (
                <div>
                  {`${I18n.t("js.calendars.date_range.end")}: `}
                  <time className="font-semibold">{formattedEnd}</time>
                </div>
              )}
              {!date.all_day && <FormattedTimeZone />}
              {isRecurring && (
                <p>
                  <b>
                    {I18n.t(
                      "js.calendars.recurring_appointment.next_occurrence",
                    )}{" "}
                  </b>
                  {next_occurrence_url && next_occurrence_date ? (
                    <a href={next_occurrence_url}>
                      {formatDate(
                        new Date(next_occurrence_date),
                        I18n.t("js.time.formats.date_fns.long_datetime"),
                      )}
                    </a>
                  ) : (
                    I18n.t(
                      "js.calendars.recurring_appointment.no_next_occurrence",
                    )
                  )}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
