import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import FieldError from "components/appCreator/items/form/FieldError";
import SimpleInput from "components/appCreator/items/form/SimpleInput";
import MarkdownEditor from "components/shared/markdownEditor";

import styles from "./editFields.module.css";
import { isPlainObject, isFunction } from "lodash";

function MarkdownEditorAdapter({ input, meta, disabled, inputRef }) {
  const setComposerRefCallback = useCallback(
    (newRef) => {
      if (isFunction(inputRef)) inputRef(newRef);
      else if (isPlainObject(inputRef)) inputRef.current = newRef;
    },
    [inputRef],
  );

  return (
    <div className={`${styles.Text} property-${input.name}`}>
      <MarkdownEditor
        {...input}
        key={meta.initial} // Recreate MarkdownEditor component if the initialValue has changed
        content={input.value}
        onChange={input.onChange}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        readOnly={disabled}
        setComposerRefCallback={setComposerRefCallback}
      />
      {meta.error && <FieldError error={meta.error} />}
    </div>
  );
}

MarkdownEditorAdapter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
  }),
  meta: PropTypes.shape(FieldError.propTypes),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

function TextField({
  name,
  required,
  multiline,
  disabled,
  id,
  autoFocus,
  inputRef,
  maxlength,
}) {
  const props = multiline
    ? {
        component: MarkdownEditorAdapter,
        inputRef: inputRef,
      }
    : { component: SimpleInput, ref: inputRef };

  return (
    <Field
      id={id}
      type="text"
      className={`property-${name} form-input ${styles.Text}`}
      name={name}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      maxLength={maxlength}
      {...props}
    />
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  maxlength: PropTypes.number,
};

export default TextField;
