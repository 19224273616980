import React, { useContext } from "react";
import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import { times } from "lodash";
import classNames from "classnames";
import { smartChunk } from "components/channels/widgets/smartChunk";
import Loading from "components/shared/Loading";
import { getWidgetContentColumns } from "components/channels/helpers";
import { useParams } from "helpers/tixxt-router";

const N_PER_COL = 4;

export default function ImagesWidget({
  id,
  images,
}: {
  id?: string;
  images?: { id: string }[];
}) {
  const { w } = useContext(WidgetContext);
  const params = useParams();

  const byColumn = images ? smartChunk(images, N_PER_COL, w) : [];
  const hiddenCount = images ? images.length - w * N_PER_COL : 0;

  return (
    <div
      className={classNames(
        "grid gap-1 overflow-hidden grid-cols-1 rounded-bl rounded-br",
        getWidgetContentColumns(w),
      )}
    >
      {times(w, (n) => {
        const layout =
          byColumn[n]?.length > 2 ? 4 : byColumn[n]?.length == 2 ? 2 : 1;

        return (
          <div
            key={n}
            className={`grid gap-1 overflow-hidden images-widget--${layout}`}
          >
            {byColumn[n]?.map((image, m) => {
              const hasHidden = hiddenCount > 0 && n == w - 1 && m == 3;
              return (
                <div
                  key={m}
                  className={classNames({ "relative group": hasHidden })}
                >
                  {image.id ? (
                    <a
                      href={`/channels/${params.channelSlug}/widgets/${id}`}
                      className={
                        "flex overflow-hidden items-center justify-center w-full h-full"
                      }
                    >
                      <img
                        alt=""
                        aria-hidden
                        src={`/api/storage/images/${image.id}/get/435x435`}
                        className={classNames(
                          "w-full h-full object-cover object-center transition hover:scale-110",
                          {
                            "grayscale blur-sm group-hover:blur-none group-hover:scale-110":
                              hasHidden,
                          },
                        )}
                      />
                    </a>
                  ) : (
                    <Loading />
                  )}
                  {hasHidden && (
                    <a
                      href={`/channels/${params.channelSlug}/widgets/${id}`}
                      className={
                        "absolute inset-0 flex justify-center items-center gap-1 " +
                        "text-white text-5xl font-bold hover:text-white hover:no-underline " +
                        "backdrop-brightness-50 hover:backdrop-brightness-75 transition"
                      }
                    >
                      <span>+</span>
                      <span>{hiddenCount}</span>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
