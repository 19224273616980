import React from "react";
import { Activitystream } from "../../../api/activitystreams";
import ReactSelect from "react-select";
import { groupBy, map, orderBy, toLower } from "lodash";
import Label from "components/shared/form/Label";

type StreamTargeting = {
  streams: Activitystream[];
  selectedStreams: Activitystream[];
  onSelectStreams: (slug: string[]) => void;
};

export default function StreamTargeting({
  streams,
  selectedStreams,
  onSelectStreams,
}: StreamTargeting) {
  const groupedStreams = map(
    groupBy(
      streams,
      (stream) =>
        stream.category_name ||
        I18n.t(
          "js.activitystreams.composer.stream_targeting.selection.network_streams",
        ),
    ),
    (options, key) => {
      const serializedOptions = map(options, (opt) => ({
        label: opt.name,
        value: opt.slug,
      }));
      return {
        label: key,
        options: orderBy(
          serializedOptions,
          (stream) => toLower(stream.label),
          "asc",
        ),
      };
    },
  );

  return (
    <div className="mb-2">
      <Label label={I18n.t("composer.editor.stream_targeting.label")} />
      <ReactSelect
        options={groupedStreams}
        value={map(selectedStreams, (stream) => ({
          label: stream.name,
          value: stream.slug,
        }))}
        onChange={(options) => onSelectStreams(map(options, "value"))}
        placeholder={I18n.t("composer.editor.stream_targeting.label")}
        noOptionsMessage={() => I18n.t("js.plugins.select2.no_match")}
        className="form-select-container"
        classNamePrefix="form-select"
        closeMenuOnSelect
        unstyled
        isMulti
        isSearchable
      />
    </div>
  );
}
