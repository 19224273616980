import React, { Fragment, useState } from "react";

import preventDefault from "../../../helpers/components/preventDefault";
import FileSelectModal from "./FileSelectModal";
import { FileData } from "components/../@types/files";

type FilesEditorViewArgs = {
  currentRootFolder: unknown;
  onSelectFiles: (selectedFiles: FileData[]) => void;
  disabled?: boolean;
};

export default function FilesEditorView({
  currentRootFolder,
  onSelectFiles,
  disabled = false,
}: FilesEditorViewArgs) {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  return (
    <Fragment>
      {" "}
      <span>{I18n.t("js.or")}</span>
      <button
        className="btn btn-light btn-sm file-selector-select"
        onClick={preventDefault(() => setShowModal(true))}
        disabled={disabled}
      >
        <i className="fa-regular fa-file pr-1" />
        {I18n.t("js.files.file_selector.select")}
      </button>
      {showModal && (
        <FileSelectModal
          closeModal={closeModal}
          currentRootFolder={currentRootFolder}
          onSelectFiles={onSelectFiles}
        />
      )}
    </Fragment>
  );
}
