import React from "react";
import MarkdownTranslation from "components/shared/MarkdownTranslation";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

function InfoText({
  translation,
  className,
  showIcon = true,
  small = false,
}: {
  translation: string;
  showIcon?: boolean;
  small?: boolean;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        "flex align-baseline",
        {
          "gap-2": small,
          "mb-4": small,
          "gap-4": !small,
          "mb-6": !small,
        },
        className,
      )}
    >
      {showIcon && (
        <FontAwesomeIcon
          icon={faCircleInfo}
          className={classNames("text-muted", {
            "ml-0": small,
            "ml-4": !small,
            "text-2xl": small,
            "text-4xl": !small,
          })}
        />
      )}
      <MarkdownTranslation
        translation={translation}
        styles="markdown-content prose max-w-none text-muted text-sm"
      />
    </div>
  );
}

export default InfoText;
