import React from "react";
import { useEffect, useState } from "react";

export default function useContainerWidth(
  containerRef: React.RefObject<HTMLDivElement> | null,
) {
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef?.current) {
        const elementWidth =
          containerRef?.current.getBoundingClientRect().width;
        setContainerWidth(elementWidth);
      }
    });

    if (containerRef?.current) {
      resizeObserver.observe(containerRef?.current);
    }

    return () => {
      if (containerRef?.current) {
        resizeObserver.unobserve(containerRef?.current);
      }
    };
  }, []);

  return containerWidth;
}
