import React from "react";
import { includes, map } from "lodash";
import Loading from "components/shared/Loading";
import classNames from "classnames";
import { LayoutRow, LayoutWidget } from "components/channels/types";
import WidgetContainer from "components/channels/widgets/WidgetContainer";
import { useWidget } from "components/channels/api";
import WidgetByType from "components/channels/widgets/WidgetByType";
import PageLoading from "components/shared/PageLoading";
import {
  getWidgetColspan,
  getWidgetContainerResponsiveHeight,
  getWidgetContentColumns,
} from "components/channels/helpers";

type LayoutData = {
  rows: LayoutRow[];
};

type ChannelLayoutArgs = {
  channelSlug?: string;
  layoutData: LayoutData | undefined;
  isLoading: boolean;
};

const W_LOOKUP = {
  full: [3],
  "half-1-2": [1, 2],
  "half-2-1": [2, 1],
  third: [1, 1, 1],
};

export function ChannelLayout({
  layoutData,
  isLoading,
  channelSlug,
}: ChannelLayoutArgs) {
  if (isLoading || !layoutData) return <PageLoading />;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
      {map(layoutData.rows, (item) =>
        map(
          item.widgets as LayoutRow<typeof item.type>["widgets"],
          (widget: LayoutWidget | null, index) => (
            <Widget
              key={index}
              w={W_LOOKUP[item.type][index]}
              channelSlug={channelSlug}
              widgetId={widget?.id}
            />
          ),
        ),
      )}
    </div>
  );
}

function Widget({
  widgetId,
  w,
  channelSlug,
}: {
  channelSlug?: string;
  widgetId?: string;
  w: number;
}) {
  const { data: widget, isLoading } = useWidget({ channelSlug, widgetId });

  if (isLoading) {
    return (
      <div className={`animate-pulse ${getWidgetColspan(w)}`}>
        <div
          className={classNames(
            `
            bg-gray-300 rounded
            flex justify-center items-center
            col-span-1
          `,
            getWidgetContainerResponsiveHeight(w),
          )}
        >
          <Loading />
        </div>
      </div>
    );
  }

  return widget ? (
    <WidgetContainer
      w={w}
      title={widget.title}
      titleBackgroundOverride={widget.title_background_override}
      collapseToSingleColumn={includes(
        ["blog", "spotlight", "banner", "link"],
        widget.type,
      )}
      singleRowOnly={includes(["appointments"], widget.type)}
      label={widget.label}
      hideTitle={widget.hide_title || widget.type === "banner"}
    >
      <WidgetByType {...widget} />
    </WidgetContainer>
  ) : (
    <div
      className={classNames(
        "flex",
        getWidgetContainerResponsiveHeight(w),
        getWidgetContentColumns(w),
      )}
    />
  );
}
