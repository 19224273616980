import { map, partition, fromPairs, isEmpty } from "lodash";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

// Custom Hook that Publishes all files or images with state 'volatile' which exist in values.
// Used before saving items to apps api,before sending message with attachments,
// etc.
//
// @example
//   const publishAllVolatile = usePublishAllVolatile();
//   const result = await publishAllVolatile({
//     values: { profile_image: [{ id: 123, state: "volatile", ... }] },
//     storageDirectory: 'images',
//   });
//
//   console.log(result)
//   //=> { profile_image: [{ id: 123, state: "published", token: 456, ... }] }
type PublishFilesArgs = {
  body: { [key: string]: any };
  fieldName: string;
  others: File[];
  storageDirectory: string;
};
const usePublishAllVolatile = () => {
  const publishFiles = useMutation<
    { [key: string]: File[] }[],
    unknown,
    PublishFilesArgs
  >(({ body, storageDirectory }: PublishFilesArgs) => {
    return fetchApi(`/api/storage/${storageDirectory}/publish`, {
      method: "POST",
      body,
    });
  });

  const destroyFiles = useMutation(
    ({
      id,
      storageDirectory,
    }: {
      id: string;
      fieldName: string;
      storageDirectory: string;
    }) => {
      return fetchApi(`/api/storage/${storageDirectory}/${id}`, {
        method: "DELETE",
      });
    },
  );

  const publishAllVolatile = async ({ values, storageDirectory }) => {
    const pairs = await Promise.all(
      map(values, async (files, fieldName) => {
        const [volatile, nonVolatile] = partition(files, { state: "volatile" });
        const [remove, others] = partition(nonVolatile, { state: "removed" });

        if (isEmpty(volatile) && isEmpty(remove)) return [fieldName, files];

        const published = await publishFiles.mutateAsync({
          body: { [storageDirectory]: volatile },
          storageDirectory,
          fieldName,
          others,
        });

        await Promise.all(
          map(remove, async (file) => {
            await destroyFiles.mutateAsync({
              id: file.id,
              storageDirectory,
              fieldName,
            });
          }),
        );

        const removed = map(remove, (file) => ({ ...file, _destroy: true }));

        return [fieldName, [...others, ...published, ...removed]];
      }),
    );
    return fromPairs(pairs);
  };

  return publishAllVolatile;
};

export default usePublishAllVolatile;
