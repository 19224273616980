import React from "react";
import {
  AppointmentDate as AppointmentDateType,
  AppointmentMeeting as AppointmentMeetingType,
} from "../../../@types/appointments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faClone,
  faDesktop,
  faPlayCircle,
} from "@fortawesome/pro-regular-svg-icons";
import useTooltip from "../../../hooks/useTooltip";
import { useAppointmentDate } from "components/appointments/details/AppointmentDate";

type AppointmentMeetingArgs = {
  date: AppointmentDateType;
  meeting: AppointmentMeetingType;
  appointmentName: string;
  canStartMeeting: boolean;
  appointmentId: string;
};

export default function AppointmentMeeting({
  date,
  meeting,
  appointmentName,
  canStartMeeting,
  appointmentId,
}: AppointmentMeetingArgs) {
  const tooltipRef = useTooltip<HTMLAnchorElement>();

  const { formattedStart, formattedEnd } = useAppointmentDate(date);

  const dateStr = formattedEnd
    ? `${formattedStart} - ${formattedEnd}`
    : formattedStart;

  function copyToClipboard() {
    navigator.clipboard
      .writeText(
        `${appointmentName}\n${dateStr}\n\n${I18n.t(
          "js.calendars.appointment.meeting.dial_in_number",
        )} ${meeting.conference_call_phone_number}\n${I18n.t(
          "js.calendars.appointment.meeting.access_code",
        )} ${meeting.conference_call_access_code}\n${I18n.t(
          "js.calendars.appointment.meeting.join_link",
        )} ${meeting.join_url}\n\n${I18n.t(
          "js.calendars.appointment.meeting.app_url",
        )} ${meeting.app_url}`,
      )
      .then(() => {
        toastr.success(I18n.t("js.calendars.appointment.meeting.copied"));
      })
      .catch(() => {
        toastr.error(I18n.t("js.generic_error"));
      });
  }

  return (
    <div className="detail-meeting detail-block">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <FontAwesomeIcon className="detail-icon" icon={faComments} />
          <strong>{I18n.t("js.calendars.appointment.meeting.label")}</strong>
          <span className="room-name">{meeting.room_name}</span>
        </div>
        <a
          ref={tooltipRef}
          data-bs-toggle="tooltip"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            copyToClipboard();
          }}
          className="copy-to-clipboard text-xl"
          title={I18n.t("js.calendars.appointment.meeting.copy")}
        >
          <FontAwesomeIcon icon={faClone} />
        </a>
      </div>
      <div className="flex flex-row mt-2 justify-evenly">
        <div>
          <p className="text-center">
            <a
              href={meeting.join_url}
              className="btn btn-primary"
              target="_blank"
              rel="nofollow noopener noreferrer"
              title={I18n.t("js.calendars.appointment.meeting.join_link")}
            >
              <FontAwesomeIcon className="mr-2" icon={faDesktop} />
              {I18n.t("js.calendars.appointment.meeting.join")}
            </a>
          </p>
          {canStartMeeting && (
            <p className="text-center mt-2">
              <a
                href={`${appointmentId}/start-meeting`}
                className="btn btn-light"
                target="_blank"
                rel="nofollow noopener noreferrer"
                title={I18n.t("js.calendars.appointment.meeting.start_link")}
                data-method="post"
              >
                <FontAwesomeIcon className="mr-2" icon={faPlayCircle} />
                {I18n.t("js.calendars.appointment.meeting.start_link")}
              </a>
            </p>
          )}
        </div>
        <div>
          <p>
            {I18n.t("js.calendars.appointment.meeting.dial_in_number")}
            <br />
            <span>{meeting.conference_call_phone_number}</span>
          </p>
          <p>
            {I18n.t("js.calendars.appointment.meeting.access_code")}
            <br />
            <span>{meeting.conference_call_access_code}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
