import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Sorter from "../../shared/tables/Sorter";
import { map, isEmpty, isNumber, filter } from "lodash";
import DefaultFileRow from "./FileRow";
import DefaultFolderRow from "./FolderRow";
import useExtraColumns from "./useExtraColumns";

function Status({ t, colSpan, count, total }) {
  return (
    <tr>
      <td className="text-center p-4 text-muted italic" colSpan={colSpan}>
        {I18n.t(`js.files.folder.${t}`)}
        {count !== undefined && count > 0
          ? ` (${count}${total !== undefined ? `/${total}` : ""})`
          : null}
      </td>
    </tr>
  );
}

const FolderContentsTable = React.memo(function FolderContentsTable({
  contents,
  total_files_count,
  total_subfolders_count,
  onNavigateItem,
  sortBy,
  handleSort,
  extraColumns = [],
  loading,
  error,
  FileRow = DefaultFileRow,
  FolderRow = DefaultFolderRow,
  extraPreColumn,
  headerRef,
  onlyShowName,
  skipFileAttributes,
  context,
  rowProps = () => null,
}) {
  const filteredContents = filter(
    contents,
    (content) => content.id != undefined,
  );

  const handleNavigate = useCallback(
    (e) => {
      if (!onNavigateItem) return null;

      e.preventDefault();
      const { id, type } = e.currentTarget.dataset;
      onNavigateItem({ id, type });
    },
    [onNavigateItem],
  );

  const [renderTable, setRenderTable] = useState(contents?.length !== 0);
  useEffect(() => {
    if (!loading) {
      const timeoutID = setTimeout(() => {
        setRenderTable(true);
      }, 50);
      return () => clearTimeout(timeoutID);
    }
  }, [loading]);

  return (
    <table className="tixxt-table min-w-full text-sm">
      <thead className="sticky top-0 bg-white">
        <tr ref={headerRef}>
          {extraPreColumn ? extraPreColumn : null}
          <th className="col-icon" />
          <th className="col-name">
            <Sorter
              attribute="name"
              label={I18n.t("js.files.folder.file_name")}
              handleSort={handleSort}
              sortBy={sortBy}
            />
          </th>
          {!onlyShowName ? (
            <>
              {map(extraColumns, ({ Header }, i) => (
                <Header key={i} handleSort={handleSort} sortBy={sortBy} />
              ))}
              {!skipFileAttributes ? (
                <>
                  <th className="col-size">
                    <Sorter
                      attribute="size"
                      label={I18n.t("js.files.folder.file_size")}
                      handleSort={handleSort}
                      sortBy={sortBy}
                    />
                  </th>
                  <th className="col-author">
                    <Sorter
                      attribute="author"
                      label={I18n.t("js.files.folder.file_author")}
                      handleSort={handleSort}
                      sortBy={sortBy}
                    />
                  </th>
                  <th className="col-updated-at">
                    <Sorter
                      attribute="updated_at"
                      label={I18n.t("js.files.folder.file_updated_at")}
                      handleSort={handleSort}
                      sortBy={sortBy}
                    />
                  </th>
                  <th className="col-actions" />
                </>
              ) : null}
            </>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {!renderTable && (
          <Status
            t="loading"
            colSpan={6 + extraColumns.length}
            count={filteredContents.length}
            total={
              isNumber(total_files_count) && isNumber(total_subfolders_count)
                ? total_files_count + total_subfolders_count
                : undefined
            }
          />
        )}
        {!loading && error ? (
          <Status t="loading_error" colSpan={6 + extraColumns.length} />
        ) : null}
        {!loading && !error && isEmpty(filteredContents) ? (
          <Status t="empty_message" colSpan={6 + extraColumns.length} />
        ) : null}
        {renderTable &&
          map(filteredContents, (item) =>
            item.type === "file" ? (
              <FileRow
                key={item.folder_link_id || item.id}
                {...item}
                handleNavigate={handleNavigate}
                extraColumns={extraColumns}
                onlyShowName={onlyShowName}
                {...rowProps(item)}
              />
            ) : (
              <FolderRow
                key={item.id}
                {...item}
                handleNavigate={handleNavigate}
                extraColumns={extraColumns}
                onlyShowName={onlyShowName}
                context={context}
                {...rowProps(item)}
              />
            ),
          )}
      </tbody>
    </table>
  );
});

FolderContentsTable.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(DefaultFolderRow.propTypes),
      PropTypes.shape(DefaultFileRow.propTypes),
    ]),
  ),
  total_files_count: PropTypes.number,
  total_subfolders_count: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.node,
  onNavigateItem: PropTypes.func,
  sortBy: PropTypes.any, // [string, number] tuple
  handleSort: PropTypes.func,
  extraColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.func.isRequired,
      Data: PropTypes.func.isRequired,
    }),
  ),
  FileRow: PropTypes.elementType,
  FolderRow: PropTypes.elementType,
  extraPreColumn: PropTypes.node,
  rowProps: PropTypes.func,
  headerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onlyShowName: PropTypes.bool,
  skipFileAttributes: PropTypes.bool,
};

export default FolderContentsTable;

export const FolderContentsTableWithProfile = React.memo(
  function FolderContentsTableWithProfile({
    contextId,
    contextType,
    ...tableProps
  }) {
    const { loading, extraColumns } = useExtraColumns({
      contextId,
      contextType,
    });

    if (loading)
      return (
        <div className="text-center p-4 text-muted italic">
          {I18n.t("js.files.folder.loading")}
        </div>
      );

    return <FolderContentsTable {...tableProps} extraColumns={extraColumns} />;
  },
);

FolderContentsTableWithProfile.propTypes = {
  contextId: PropTypes.string,
  contextType: PropTypes.string.isRequired,
};
