import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faCircleUser,
  faComputerClassic,
  faTag,
  faBullhorn,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {
  ChannelEntry,
  GroupAdminEntry,
  GroupEntry,
  MemberEntry,
} from "components/directory/api";
import { isEmpty } from "lodash";
import {
  DisabledOption,
  MembershipRole,
  PermissionPickable,
  PermissionPickableWithDisabled,
  SystemRole,
  ValueType,
} from "components/shared/DirectoryPicker/types";
import { useQuery } from "react-query";
import classnames from "classnames";
import "./optionRendering.css";

// Externally-visible signature, for compile time check that switch uses all types
export function throwBadType(entry: never): never;
// Implementation signature, called if invalid type is provided during runtime
export function throwBadType(entry: PermissionPickableWithDisabled) {
  throw new Error(
    "Unhandled option type: " + entry.type + " in " + JSON.stringify(entry),
  );
}

/**
 * Turns a given option value into a react element
 * If display attributes (like name) are missing, they are fetched by the react components
 *
 * @param entry
 */
export function formatOptionLabel(
  entry: ValueType | PermissionPickableWithDisabled,
) {
  if (!isOptionEnabled(entry)) return noOptionsMessage({ inputValue: "" });

  switch (entry.type) {
    case "member":
      return <MemberOptionLabel {...entry} />;

    case "group":
      return <GroupOptionLabel {...entry} />;

    case "group_admin":
      return <GroupAdminOptionLabel {...entry} />;

    case "system_role":
      return <SystemRoleOptionLabel {...entry} />;

    case "membership_role":
      return <MembershipRoleOptionLabel {...entry} />;

    case "channel":
      return <ChannelOptionLabel {...entry} />;

    default:
      throwBadType(entry);
  }
}

function MemberOptionLabel({ id, name, images }: MemberEntry) {
  const { data, isLoading } = useQuery<MemberEntry>(`/members/${id}`, {
    enabled: !name,
  });

  return (
    <div className="flex gap-2">
      {images ? (
        <img className="member-image h-6 w-6" src={images.small} />
      ) : (
        <FontAwesomeIcon key="icon" icon={faUser} className="fa-fw" />
      )}
      {isLoading
        ? I18n.t("js.loading")
        : name || data?.name || I18n.t("js.memberships.deleted_name")}
    </div>
  );
}

function ChannelOptionLabel({ slug, title, image }: ChannelEntry) {
  const { data, isLoading } = useQuery<ChannelEntry>(`/channels/${slug}`, {
    enabled: !slug,
  });

  return (
    <div className="flex gap-2">
      {image._id ? (
        <img
          className="member-image h-6 w-6"
          src={`/api/storage/images/${image._id}/get`}
        />
      ) : (
        <FontAwesomeIcon key="icon" icon={faBullhorn} className="fa-fw" />
      )}
      {isLoading ? I18n.t("js.loading") : title || data?.title}
    </div>
  );
}

function GroupOptionLabel({ id, name }: GroupEntry) {
  const { data, isLoading } = useQuery<GroupEntry>(`/groups/${id}`, {
    enabled: !name,
  });

  return (
    <>
      <FontAwesomeIcon key="icon" icon={faUsers} className="fa-fw mr-2" />
      {isLoading
        ? I18n.t("js.loading")
        : `${name || data?.name || I18n.t("js.groups.unknown_group")} (${I18n.t(
            "js.member_select.all_group_members",
          )})`}
    </>
  );
}

function GroupAdminOptionLabel({ id, name }: GroupAdminEntry) {
  const { data, isLoading } = useQuery<GroupAdminEntry>(`/groups/${id}`, {
    enabled: !name,
  });

  return (
    <>
      <FontAwesomeIcon
        key="icon"
        icon={faCircleUser}
        className="fa-fw mr-2 group_indentation"
      />
      {isLoading
        ? I18n.t("js.loading")
        : `${name || data?.name || I18n.t("js.groups.unknown_group")} (${I18n.t(
            "js.member_select.only_admins",
          )})`}
    </>
  );
}

function SystemRoleOptionLabel({ id, name }: SystemRole) {
  return (
    <>
      <FontAwesomeIcon
        key="icon"
        icon={faComputerClassic}
        className="fa-fw mr-2"
      />
      {name || I18n.t(`js.permission_picker.system_roles.${id}`)}
    </>
  );
}

function MembershipRoleOptionLabel({
  id,
  name,
  parent_type,
  parent_id,
}: MembershipRole) {
  const { data: groupData, isLoading } = useQuery<GroupEntry>(
    `/groups/${parent_id}`,
    {
      enabled: parent_type == "group" && !name,
    },
  );
  let data: Pick<MembershipRole, "name"> | undefined;
  if (parent_type == "group")
    data = groupData?.membership_roles.find((r) => r.id == id);
  else data = Preload.current_network.membership_roles.find((r) => r.id == id);

  return (
    <>
      <FontAwesomeIcon
        key="icon"
        icon={faTag}
        className={classnames("fa-fw mr-2", {
          group_indentation: parent_type == "group",
        })}
      />
      {isLoading
        ? I18n.t("js.loading")
        : name || data?.name || I18n.t("js.permission_picker.unknown_role")}
    </>
  );
}

function isOptionEnabled(entry: ValueType): entry is PermissionPickable {
  return !("disabled" in entry);
}

export function isOptionDisabled(entry: ValueType): entry is DisabledOption {
  return !isOptionEnabled(entry);
}

export const noOptionsMessage = ({ inputValue }) =>
  isEmpty(inputValue)
    ? I18n.t("js.plugins.select2.enter_more_characters.one")
    : I18n.t("js.plugins.select2.no_match");
