import React from "react";

export default function FormActions({
  onCancel,
  saveDisabled,
  children,
  showCancel = true,
  showSave = true,
}: {
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  saveDisabled?: boolean;
  children?: React.ReactNode;
  showCancel?: boolean;
  showSave?: boolean;
}) {
  return (
    <div className="tixxt__form--actions">
      {showCancel && (
        <button onClick={onCancel} className="btn btn-light">
          {I18n.t("js.cancel")}
        </button>
      )}
      {showSave && (
        <button className="btn btn-primary" disabled={saveDisabled}>
          {I18n.t("js.save")}
        </button>
      )}
      {children}
    </div>
  );
}
