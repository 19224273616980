import React from "react";
import { useParams, useRoutes } from "helpers/tixxt-router";
import { ColorObject } from "components/administration/designManager/types";
import { useChannel } from "components/channels/api";
import { createPortal } from "react-dom";
import { map } from "lodash";
import { colorObjToCSSProperties } from "components/administration/designManager/themeToCss";

const routes = [
  { path: "/channels/:channelSlug", element: <ChannelOverride /> },
  { path: "/channels/:channelSlug/*", element: <ChannelOverride /> },
  { path: "/channels/:channelSlug/design/edit", element: <NoChannel /> },
  { path: "*", element: <NoChannel /> },
];

export default function ApplyOverrideChannelTheme() {
  const element = useRoutes(routes);

  return element;
}

function ChannelOverride() {
  const { channelSlug } = useParams();
  const { data: channel } = useChannel(channelSlug);

  if (channel?.design) {
    const css = channelDesignToCss(channel.design);

    return createPortal(<style>{css}</style>, document.head);
  }

  return null;
}

function NoChannel() {
  return null;
}

export function channelDesignToCss(theme: {
  derivedColors?: {
    [name: string]: ColorObject | undefined;
  } | null;
}) {
  const { derivedColors } = theme;
  const cssContent: string[] = [];

  cssContent.push(":root {");

  for (const variable in derivedColors) {
    if (variable.indexOf("channel-") != 0) continue;

    const colorObj = derivedColors[variable];

    cssContent.push(
      map(
        colorObjToCSSProperties(variable, colorObj),
        (value, variable) => `${variable}: ${value};`,
      ).join(" "),
    );
  }

  cssContent.push(`}`);

  return cssContent.join("\n");
}
