import React from "react";
import { UseFormRegister } from "react-hook-form";
import Hint from "components/shared/fields/Hint";
import Label from "components/shared/form/Label";
import useRegisterPropOrFromContext from "components/shared/form/fields/useRegisterPropOrFromContext";

type InputArgs = {
  name: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  register?: UseFormRegister<any>;
  options: { label: string; value: string }[];
  hint?: string;
};

export default function RadioButton({
  name,
  register,
  label,
  options,
  hint,
  required,
  defaultValue,
}: InputArgs) {
  register = useRegisterPropOrFromContext(register);

  return (
    <div className="control-group">
      {label && <Label label={label} required={required} />}
      <div className="controls white-box checkboxes border-box p-3">
        {options.map((option) => (
          <label key={option.value} className="radio gap-2 items-center">
            <input
              type="radio"
              value={option.value}
              {...(register ? register(name) : null)}
              defaultChecked={option.value === defaultValue}
            />
            {option.label}
          </label>
        ))}
        {hint && <Hint hint={hint} />}
      </div>
    </div>
  );
}
