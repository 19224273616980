import React, { useEffect, useState } from "react";
import { flatten, includes, map } from "lodash";
import preventDefault from "../../helpers/components/preventDefault";
import UnreadBadge from "components/shared/UnreadBadge";
import InvitesList from "components/invites/InvitesList";
import { useFeaturesUnreads } from "../../api/unreads";
import { useInvites } from "components/invites/api";
import { useInfiniteQuery } from "react-query";
import fetchPaginatedData from "helpers/api/fetchPaginatedData";
import PageTitle from "components/layout/PageTitle";
import classNames from "classnames";

function useParticipations({ status, enabled }) {
  return useInfiniteQuery(
    ["participations", status],
    ({ pageParam = `/api/v1/participations?status=${status}&limit=20` }) =>
      fetchPaginatedData({ pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled,
    },
  );
}

export default function InvitesPage() {
  const { data: unreads, refetch: reloadUnreads } = useFeaturesUnreads();
  const [activeTab, setActiveTab] = useState("open");
  const isInvitesTab = includes(["open", "expired", "obsolete"], activeTab);

  const {
    data: invitesData,
    isLoading: isLoadingInvites,
    refetch: reloadInvites,
    fetchNextPage: loadMoreInvites,
    hasNextPage: canLoadMoreInvites,
  } = useInvites({
    filter: "appointments",
    status: activeTab,
    enabled: isInvitesTab,
  });

  const {
    data: participationsData,
    isLoading: isLoadingParticipations,
    refetch: reloadParticipations,
    fetchNextPage: loadMoreParticipations,
    hasNextPage: canLoadMoreParticipations,
  } = useParticipations({
    status: activeTab,
    enabled: !isInvitesTab,
  });

  const [items, setItems] = useState(
    isInvitesTab
      ? {
          type: "invites",
          data: flatten(map(invitesData?.pages, (page) => page.data)),
        }
      : {
          type: "participations",
          data: flatten(map(participationsData?.pages, (page) => page.data)),
        },
  );

  function load() {
    isInvitesTab ? reloadInvites() : reloadParticipations();

    reloadUnreads();
  }

  useEffect(() => {
    const updateItems = () => {
      if (isInvitesTab) {
        setItems({
          type: "invites",
          data: flatten(map(invitesData?.pages, (page) => page.data)),
        });
      } else {
        setItems({
          type: "participations",
          data: flatten(map(participationsData?.pages, (page) => page.data)),
        });
      }
    };
    updateItems();
  }, [activeTab, invitesData, participationsData]);

  function loadMore(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    if (isInvitesTab) {
      loadMoreInvites();
    } else {
      loadMoreParticipations();
    }
  }

  const tabs = [
    "open",
    "upcoming",
    "accepted",
    "declined",
    "expired",
    "obsolete",
  ];

  return (
    <>
      <PageTitle title={I18n.t("calendars.invites.title")} />
      <div className="tixxt-tabs-layout">
        <div className="tabs-region">
          <ul className="nav nav-tabs">
            {map(tabs, (tab) => (
              <li className={classNames({ active: activeTab === tab })}>
                <a onClick={preventDefault(() => setActiveTab(tab))} href="#">
                  <div className="flex gap-1 items-center">
                    {I18n.t(`js.calendars.appointments.invites.tabs.${tab}`)}
                    {tab === "open" && (
                      <UnreadBadge count={unreads?.Calendars} />
                    )}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="tab-content-region">
          <InvitesList
            isLoading={isLoadingInvites || isLoadingParticipations}
            inviteType="appointments"
            inviteStatus={activeTab}
            invites={items}
            loadMore={loadMore}
            canLoadMore={
              canLoadMoreInvites || canLoadMoreParticipations || false
            }
            reloadInvites={load}
          />
        </div>
      </div>
    </>
  );
}
