import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";
import React from "react";
import { networkMembershipRoles } from "../components/shared/PermissionPicker";
import { Wrapper as QueryClientProvider } from "../helpers/queryClient";
import DirectoryPicker from "../components/shared/DirectoryPicker";
import AssignChannelSubscriptionsForm from "../components/administration/channels/AssignChannelSubscriptionsForm";

// Connects to data-controller="assign_channel_subscriptions_form"
// Intended be used on a div container
export default class extends Controller {
  connect() {
    this.originalType = this.element.type;
    this.element.type = "hidden";

    const container = document.createElement("div");
    this.element.insertAdjacentElement("afterend", container);
    this.root = createRoot(container);
    this.root.render(
      <QueryClientProvider>
        <AssignChannelSubscriptionsForm />
      </QueryClientProvider>,
    );
  }

  handleChange = (newValue) => {
    this.element.value = JSON.stringify(newValue);

    this.root.render(
      <QueryClientProvider>
        <DirectoryPicker
          value={newValue}
          onChange={this.handleChange}
          multi
          extraOptions={networkMembershipRoles}
          disabled={
            this.hasMembersFieldTarget && this.membersFieldTarget.disabled
          }
        />
      </QueryClientProvider>,
    );
  };

  disconnect() {
    if (this.root) {
      this.root.unmount();
      this.element.type = this.originalType;
    }
  }
}
