import classNames from "classnames";
import React, { useContext } from "react";
import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import { getWidgetContentColumns } from "components/channels/helpers";
import FileIcon from "components/shared/FileIcon";
import FolderIcon from "components/shared/FolderIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";

type ExternalLinkViewArgs = {
  image_url: string;
  url: string;
  title: string;
  description: string | null;
  type: string;
};

function getFileType(title: string): string {
  const extension = title.split(".").pop()?.toLowerCase();
  return extension || "";
}

export default function LinkView({
  image_url,
  url,
  title,
  description,
  type,
}: ExternalLinkViewArgs) {
  const { w } = useContext(WidgetContext);

  return (
    <div
      className={classNames(
        "grid h-full grid-cols-1 grid-rows-1",
        getWidgetContentColumns(w),
      )}
    >
      {w > 1 && (
        <div
          className="block bg-cover bg-center"
          style={{
            backgroundImage: `url("${image_url}")`,
          }}
        />
      )}
      <div
        className={classNames("flex flex-col p-3 gap-3", {
          "col-span-2": w === 3,
        })}
      >
        <div className="flex gap-2 items-start">
          {type === "file" && <FileIcon extension={getFileType(title)} />}
          {type === "folder" && <FolderIcon className="text-2xl" />}
          {type === "appointment" && (
            <FontAwesomeIcon className="text-2xl" icon={faCalendar} />
          )}
          <a className="font-medium" href={url}>
            {title}
          </a>
        </div>
        <div className="h-full">{description}</div>
        <a href={url}>
          <p className="text-muted text-sm overflow-hidden text-ellipsis pb-2">
            {url}
          </p>
        </a>
      </div>
    </div>
  );
}
