import React, { useState, useEffect } from "react";
import PageTitle from "components/layout/PageTitle";
import TabBar from "components/shared/TabBar";
import { findIndex, forEach, includes } from "lodash";
import MembersListView from "components/directory/members/MemberListView";
import ChannelListView from "components/directory/channels/ChannelListView";
import GroupListView from "components/directory/groups/GroupListView";
import { useParams } from "helpers/tixxt-router";

type DirectoryPageArgs = {
  preselectedTab: "channels" | "members" | "groups";
};

export default function DirectoryPage({ preselectedTab }: DirectoryPageArgs) {
  const { category_id } = useParams();
  const availableModules =
    (Tixxt.currentNetwork?.get("availableModules") as string[]) || [];

  const tabs = [
    {
      id: "members",
      type: "members",
      label: I18n.t("js.directory.member_tab"),
      url: "/members",
      component: MembersListView,
    },
  ];

  if (includes(availableModules, "Channels")) {
    tabs.push({
      id: "channels",
      type: "channels",
      label: I18n.t("js.directory.channel_tab"),
      url: "/channels",
      component: ChannelListView,
    });
  }

  forEach(Preload.current_network.group_categories, (category) =>
    tabs.push({
      id: category.slug,
      type: "groups",
      label: category.plural_name,
      url: `/directory/${category.slug}`,
      component: GroupListView,
    }),
  );

  const tabIndex = findIndex(tabs, { id: category_id || preselectedTab });
  const [selectedTabIndex, selectTabIndex] = useState(tabIndex);

  useEffect(() => {
    selectTabIndex(tabIndex);
  }, [tabIndex]);

  const { component: TabComponent, type: selectedTabType } =
    tabs[selectedTabIndex];

  return (
    <>
      <PageTitle title={I18n.t(`js.directory.title.${selectedTabType}`)} />
      <TabBar
        tabs={tabs}
        selectTabIndex={selectTabIndex}
        selectedTabIndex={selectedTabIndex}
        className={"mb-2"}
      />
      {TabComponent && <TabComponent />}
    </>
  );
}
