import React from "react";

export default function BannerWidget({
  image,
  title,
  banner_background_color,
  hide_title,
  banner_title_color,
}: {
  id?: string;
  image?: { id: string; state: string };
  banner_background_color: string;
  banner_title_color: string;
  title: string;
  hide_title?: boolean;
}) {
  return (
    <div
      className="w-full bg-cover bg-center flex flex-col justify-end rounded h-[24rem]"
      title={title}
      style={
        image?.id && image?.state !== "removed"
          ? {
              backgroundImage: `url("/api/storage/images/${image.id}/get/1920x1200")`,
            }
          : { backgroundColor: banner_background_color }
      }
    >
      {title && !hide_title && (
        <div
          className="flex text-4xl font-bold text-center p-4"
          style={{ color: banner_title_color }}
        >
          {title}
        </div>
      )}
    </div>
  );
}
